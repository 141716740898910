@import "Colors"
@import "Width"
    
.footer
    background-color: $background-black
    @media ( min-width: $md )
        padding-top: 68px
        padding-bottom: 135px
        
        .inner
            .copyrights
                img
                    width: 128px
                p
                    font-size: 14px
                    line-height: 1.43
                    color: #989898
            .right
                p
                    font-size: 18px
                    line-height: 1.5
                    color: #b0b0b0
                .links
                    .page-link
                        display: block
                        font-size: 18px
                        line-height: 1.5
                        color: #ffffff
                        margin-bottom: 5px
                        transition: color 0.1s ease-out
                        &:hover
                            color: #efefef
                            
    @media ( max-width: $md )
        padding: 60px 20px
        .logo
            margin-bottom: 48px
            img
                width: 103px
                height: 28px
        .links
            margin-bottom: 48px
            p
                font-size: 14px
                line-height: 1.5
                color: #b0b0b0
            .page-link
                display: block
                font-size: 14px
                line-height: 1.5
                color: #ffffff
                margin-bottom: 5px
                transition: color 0.1s ease-out
                &:hover
                    color: #efefef
        .copyrights
            color: #989898
            font-size: 12px
            .page-link
                display: inline-block
                color: #ffffff
                transition: color 0.1s ease-out
                &:hover
                    color: #efefef
        