.login-page
    display: flex
    align-items: center
    width: 100%
    height: 100%
    background-color: black
    padding-top: 96px

    .row
        width: 100%
        display: flex

        @media (max-width: 600px)
            padding: 0 16px


        @media (min-width: 600px)
            padding: 0 20%

        .left-col
            display: flex
            align-items: center
            @media (max-width: 1400px)
                visibility: hidden
                width: 0
                margin-right: 0


            @media (min-width: 1400px)
                width: 45%
                margin-right: 15%


            img
                width: 100%


        .right-col
            @media (max-width: 1400px)
                width: 100%
                padding: 24px 20px

            @media (min-width: 1400px)
                width: 40%
                padding: 48px 40px

            border-radius: 5px
            background-color: white

            .logo
                display: flex
                justify-content: center
                align-content: center
                margin-bottom: 63px

                img
                    width: 48px
                    height: 48px
                    margin-right: 16px


                p
                    font-size: 28px
                    font-weight: bold
                    line-height: 48px
                    color: #000000
                    margin: 0


            .fb-login
                width: 100%
                border-radius: 5px
                background-color: #0195f7
                display: flex
                align-items: center
                justify-content: center
                padding: 12px
                border: none
                cursor: pointer
                margin-bottom: 24px

                img
                    width: 28px
                    margin-right: 16px


                p
                    margin: 0
                    font-size: 18px
                    color: white


            .google-login
                display: flex
                align-items: center
                justify-content: center
                padding: 12px
                width: 100%
                border: solid 1.5px #b0b0b0
                background-color: #ffffff
                cursor: pointer
                border-radius: 5px
                margin-bottom: 24px

                img
                    width: 28px
                    margin-right: 16px


                p
                    margin: 0
                    font-size: 18px
                    color: black


            .apple-login
                display: flex
                align-items: center
                justify-content: center
                padding: 12px
                width: 100%
                border: solid 1.5px #b0b0b0
                background-color: #ffffff
                cursor: pointer
                margin-bottom: 24px
                border-radius: 5px

                img
                    width: 28px
                    margin-right: 16px


                p
                    margin: 0
                    font-size: 18px
                    color: black


            .login-help
                font-size: 18px
                line-height: 1.5
                text-align: right
                color: #ff7700
                cursor: pointer
                margin-bottom: 72px


            .copyrights
                font-size: 14px
                line-height: 1.43
                text-align: center




