@import "Colors.sass"
@import "Width.sass"

@keyframes fromBottom
    0%
        transform: translateY(150%)
    100%
        transform: translateY(0%)

@keyframes fromLeft
    0%
        transform: translateX(-150%)
    100%
        transform: translateX(0%)

@keyframes toLeft
    0%
        transform: translateX(0%)
    100%
        transform: translateX(-150%)

.landing
    
    h1
        line-height: 64px
    
    h2
        line-height: 48px
        
    .inner
        p
            margin-top: 16px

    .black-area
        background-color: #101010
        color: white

        @media (max-width: $sm)
            padding: 0 20px


    .white-area
        background-color: white
        color: black

        @media (max-width: $sm)
            padding: 0 20px

    h1
        @media (max-width: $sm)
            font-size: 42px
        @media (min-width: $sm)
            font-size: 56px

    h2
        @media (max-width: $sm)
            font-size: 24px
        @media (min-width: $sm)
            font-size: 42px
        color: $theme_yellow

    p
        @media (max-width: $sm)
            font-size: 16px
        @media (min-width: $sm)
            font-size: 24px

    strong
        color: $theme-yellow

    .row1

        @media (max-width: $sm)
            margin-top: 220px
            margin-bottom: 100px
        @media (min-width: $sm)
            margin-top: 120px
            margin-bottom: 120px

        .left
            display: flex
            align-items: center

            .inner
                animation: fromLeft 0.8s 0.2s cubic-bezier(0, 0, 0, 1)
                animation-fill-mode: forwards
                transform: translateX(-150%)

                width: 100%
                text-align: center

                p
                    margin-top: 28px
                    margin-bottom: 60px

                img
                    @media (max-width: $sm)
                        width: 125px
                    @media (min-width: $sm)
                        width: 180px

                    margin: 6px 18px
                    cursor: pointer

        .right
            overflow: hidden

            img
                animation: fromBottom 1s 0.5s cubic-bezier(0, 0, 0, 1)
                animation-fill-mode: forwards
                transform: translateY(150%)

                width: 100%

                &:before
                    content: ""
                    display: block
                    padding-top: 100%


    .row2
        @media (max-width: $sm)
            margin-bottom: 100px
        @media (min-width: $sm)
            margin-bottom: 120px

        .left
            img
                width: 100%

                &:before
                    content: ""
                    display: block
                    padding-top: 100%

        .right
            @media (max-width: $sm)
                margin-top: 48px

            display: flex
            align-items: center

            .inner
                width: 100%

                @media (max-width: $sm)
                    text-align: center
                @media (min-width: $sm)
                    text-align: left

                @media (max-width: $sm)
                    h2
                        margin-bottom: 12px
                    p
                        margin-top: 12px

    .row3
        background-color: white
        color: black

        @media (max-width: $sm)
            margin: 60px 0 100px 0
        @media (min-width: $sm)
            margin: 120px 0

        .left
            padding: 0 56px 56px 56px

            .creators
                .creator
                    margin-bottom: 24px

                    img
                        width: 100%

                        &:before
                            content: ""
                            display: block
                            padding-top: 100%
                        border-radius: 5px
                        box-shadow: 0 3px 6px 0 #000000
                        background-color: black

        .right
            text-align: center
            display: flex
            justify-content: center
            align-items: center

            .inner
                @media (max-width: $sm)
                    padding: 0 24px
                @media (min-width: $sm)
                    padding: 0 79px

                .logo
                    display: flex
                    justify-content: center
                    align-items: center

                    .icon
                        margin-right: 20px

                        @media (max-width: $sm)
                            height: 32px
                        @media (min-width: $sm)
                            height: 64px

                    .text
                        @media (max-width: $sm)
                            height: 22px
                        @media (min-width: $sm)
                            height: 44px

                p
                    line-height: 1.5

                    @media (max-width: $sm)
                        font-size: 16px
                    @media (min-width: $sm)
                        font-size: 24px
                        margin-bottom: 44px

                .buttons
                    width: 100%

                    button
                        @media (max-width: $sm)
                            width: 200px
                            height: 48px
                            font-size: 16px
                        @media (min-width: $sm)
                            width: 266px
                            height: 63px
                            font-size: 18px

                        margin: 12px auto
                        border-radius: 32px
                        color: white
                        background-color: #101010
                        transition: background-color 0.1s ease-out
                        border: none
                        cursor: pointer

                        &:hover
                            background-color: $theme-yellow
                            color: black

                        &:focus
                            outline: 0
