.episode-page-sticky
    @media ( min-width: 576px )
        display: none !important

.episode-page
    background-color: #111

    .section-title-wrap
        width: 100%
        margin-bottom: 24px

    .section-basic
        margin-top: 108px
        margin-bottom: 24px

    .icon-box-40
        width: 40px
        height: 40px

    .text-link
        -webkit-align-self: center
        -ms-flex-item-align: center
        -ms-grid-row-align: center
        align-self: center
        color: #15C7FF
        line-height: 140%
        font-weight: 400
        text-decoration: none

        &:hover
            text-decoration: underline

    .section-title
        margin-top: 0px
        margin-bottom: 8px
        -webkit-align-self: center
        -ms-flex-item-align: center
        -ms-grid-row-align: center
        align-self: center
        color: #fff
        font-size: 28px
        line-height: 140%

    .section-body-text
        margin-bottom: 0px
        color: #ccc
        font-size: 16px
        line-height: 160%

    &.mb16
        max-height: 840px
        margin-bottom: 16px

    .button
        padding: 15px 24px
        -webkit-align-self: flex-end
        -ms-flex-item-align: end
        align-self: flex-end
        border-radius: 12px
        background-color: #ffdd15
        -webkit-transition: all 200ms ease
        transition: all 200ms ease
        color: #111
        font-size: 16px
        line-height: 112.5%
        font-weight: 700
        text-align: center

        &:hover
            background-color: rgba(255, 221, 21, 0.8)

        &.small
            padding: 8px 12px
            -webkit-align-self: center
            -ms-flex-item-align: center
            -ms-grid-row-align: center
            align-self: center
            border-radius: 8px
            font-size: 14px

        &.mr8
            margin-right: 8px

        &.button-secondary
            border-style: solid
            border-width: 1px
            border-color: #fff
            background-color: transparent
            color: #fff

            &:hover
                background-color: hsla(0, 0%, 100%, 0.05)

    .icon-box-20
        width: 20px
        height: 20px
        -webkit-align-self: center
        -ms-flex-item-align: center
        -ms-grid-row-align: center
        align-self: center

    &.mr4
        margin-right: 4px

    .tag
        display: block
        margin-right: 4px
        margin-bottom: 0px
        margin-left: 4px
        padding: 3.5px 8px
        -webkit-align-self: center
        -ms-flex-item-align: center
        -ms-grid-row-align: center
        align-self: center
        border-style: solid
        border-width: 1px
        border-color: #fff
        border-radius: 4px
        color: #fff
        font-size: 12px
        line-height: 140%

        &.filled
            -webkit-align-self: flex-start
            -ms-flex-item-align: start
            align-self: flex-start
            border-style: none
            background-color: #4b4b4b

            &.ml0
                margin-right: 0px
                margin-bottom: 12px
                margin-left: 0px

    .comment-divider
        height: 1px
        margin-top: 24px
        margin-bottom: 24px
        margin-left: 64px
        background-color: #4b4b4b

    .h3
        margin-top: 0px
        margin-bottom: 16px
        color: #fff
        font-size: 28px
        line-height: 125%

    .h5
        margin-top: 0px
        margin-bottom: 16px
        color: #fff
        font-size: 18px
        line-height: 140%

        &.mb4
            margin-bottom: 4px
            font-size: 20px

    .h2
        margin-top: 0px
        margin-bottom: 24px
        color: #fff
        line-height: 125%

    .form-block
        width: 100%
        margin-bottom: 0px

    .section-title-underline
        width: 100%
        height: 1px
        background-color: #4b4b4b

    .play-time
        margin-bottom: 0px
        -webkit-align-self: center
        -ms-flex-item-align: center
        -ms-grid-row-align: center
        align-self: center
        font-size: 18px
        line-height: 140%

    .body-text
        margin-bottom: 0px
        color: #ccc
        font-size: 16px
        line-height: 160%
        font-weight: 400

        &.mb8
            margin-bottom: 8px

    .panel-base
        margin-right: 12px
        margin-left: 12px
        padding: 40px
        border-style: solid
        border-width: 1px
        border-color: #4b4b4b
        border-radius: 24px
        background-color: #2d2d2d
        box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.3)
        -webkit-transition: all 200ms ease
        transition: all 200ms ease

    .text-link-large
        display: block
        color: #15c7ff
        font-size: 14px
        line-height: 140%
        font-weight: 700
        text-decoration: none

        &:hover
            text-decoration: underline

        &.mb24
            margin-bottom: 36px

    .container-1200
        max-width: 1200px

        &._2-col
            display: -webkit-box
            display: -webkit-flex
            display: -ms-flexbox
            display: flex
            max-width: 820px

    .sub-nav-button-wrap
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        height: 56px
        -webkit-align-self: center
        -ms-flex-item-align: center
        align-self: center

    .sub-menu-wrap
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        height: 56px
        -webkit-box-align: center
        -webkit-align-items: center
        -ms-flex-align: center
        align-items: center
        -webkit-align-self: center
        -ms-flex-item-align: center
        align-self: center
        -webkit-box-flex: 1
        -webkit-flex: 1
        -ms-flex: 1
        flex: 1

    .nav-sub-menu
        height: 100%
        margin-right: 24px
        padding-top: 18px
        padding-bottom: 18px
        -webkit-align-self: center
        -ms-flex-item-align: center
        -ms-grid-row-align: center
        align-self: center
        border-bottom: 3px solid #2d2d2d
        -webkit-transition: all 200ms ease
        transition: all 200ms ease
        color: hsla(0, 0%, 100%, 0.5)
        line-height: 140%
        font-weight: 700
        text-decoration: none

        &:hover
            border-bottom-width: 4px
            border-bottom-color: #ffdd15
            color: #fff

        &.active
            border-style: none none solid
            border-width: 1px 1px 4px
            border-color: #000 #000 #ffdd15
            color: #fff

    .sticky-nav
        position: -webkit-sticky
        position: sticky
        top: 64px
        z-index: 1
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        height: 56px
        padding: 20px
        -webkit-box-align: center
        -webkit-align-items: center
        -ms-flex-align: center
        align-items: center
        background-color: #2d2d2d
        box-shadow: 0 8px 0 0 rgba(0, 0, 0, 0.2)

    .podcaster-info-wrap
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        margin-bottom: 24px
        padding: 0px
        -webkit-box-align: center
        -webkit-align-items: center
        -ms-flex-align: center
        align-items: center
        border-style: none
        border-width: 2px
        border-color: #4b4b4b
        border-radius: 0px

    .podcaster-photo
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        width: 56px
        height: 56px
        margin-right: 16px
        -webkit-box-pack: center
        -webkit-justify-content: center
        -ms-flex-pack: center
        justify-content: center
        -webkit-box-align: center
        -webkit-align-items: center
        -ms-flex-align: center
        align-items: center
        border-style: solid
        border-width: 1px
        border-color: #ffdd15
        border-radius: 21px

    .image-2
        width: 48px
        height: 48px
        border-radius: 16px
        cursor: pointer

    .div-block
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex

    .episode-release-time
        margin-right: 12px
        margin-bottom: 0
        color: #b0b0b0
        text-decoration: none

    .number-of-listens
        color: #b0b0b0
        margin-bottom: 0
        text-decoration: none

    .text-block
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        margin-right: 12px
        -webkit-box-align: center
        -webkit-align-items: center
        -ms-flex-align: center
        align-items: center
        color: #fff

    @media screen and (max-width: 991px)
    .nav-menu-2
        padding: 12px 16px
        background-color: #111

    .menu-button
        padding: 6px
        border-radius: 8px
        background-color: #fff

    .icon
        color: #111

    .section-body-text
        font-size: 16px

    .container-1200._2-col
        -webkit-box-orient: vertical
        -webkit-box-direction: normal
        -webkit-flex-direction: column
        -ms-flex-direction: column
        flex-direction: column

    @media screen and (max-width: 767px)
    .hoost-logo
        padding-left: 0px

    .nav-menu-2
        padding: 12px 16px
        background-color: #111

    .menu-button
        overflow: hidden
        padding: 0px
        border-radius: 8px
        background-color: #111

        &.w--open
            overflow: hidden
            border-radius: 8px
            background-color: #ececec

    .icon
        overflow: hidden
        padding: 6px
        border-radius: 8px
        background-color: #ececec
        color: #111

        &:hover
            background-color: hsla(0, 0%, 100%, 0.8)

    .section-basic
        margin-bottom: 16px

    .section-body-text
        font-size: 16px

    .button.button-secondary
        -webkit-box-flex: 0
        -webkit-flex: 0 auto
        -ms-flex: 0 auto
        flex: 0 auto

    .panel-base
        padding: 32px
        -webkit-box-ordinal-group: 1
        -webkit-order: 0
        -ms-flex-order: 0
        order: 0
        border-radius: 12px

    .container-1200._2-col
        -webkit-box-orient: vertical
        -webkit-box-direction: normal
        -webkit-flex-direction: column
        -ms-flex-direction: column
        flex-direction: column

    .sub-nav-button-wrap
        display: none

    .sticky-nav
        position: relative

    .podcaster-info-wrap
        margin-bottom: 16px
        padding: 12px

    @media screen and (max-width: 479px)
    .nav-container
        -webkit-box-pack: start
        -webkit-justify-content: flex-start
        -ms-flex-pack: start
        justify-content: flex-start

    .hoost-logo
        padding-left: 0px

    .section-basic
        margin-top: 84px
        margin-bottom: 16px

    .section-title
        font-size: 28px

    .section-body-text.mb16
        max-height: 380px

    .tag
        margin-top: 4px
        margin-right: 4px
        margin-bottom: 4px

    .comment-divider
        margin-left: 0px

    .h5.mb4
        max-height: 28px
        margin-bottom: 0
        cursor: pointer

    .h2
        font-size: 24px

    .panel-base
        margin-right: 8px
        margin-left: 8px
        padding: 24px

    .container-1200._2-col
        -webkit-box-orient: vertical
        -webkit-box-direction: normal
        -webkit-flex-direction: column
        -ms-flex-direction: column
        flex-direction: column

    .avatar-image
        width: 100%
        height: 100%

    .sub-nav-button-wrap
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex

    .sub-menu-wrap
        overflow: hidden
        -webkit-flex-wrap: nowrap
        -ms-flex-wrap: nowrap
        flex-wrap: nowrap
        -webkit-box-flex: 1
        -webkit-flex: 1
        -ms-flex: 1
        flex: 1

    .nav-sub-menu
        -webkit-box-flex: 0
        -webkit-flex: 0 0 auto
        -ms-flex: 0 0 auto
        flex: 0 0 auto

    .sticky-nav, .podcaster-info-wrap
        padding: 0px
