.sidenav
  position: fixed
  width: 100%
  height: 100vh
  top: 0
  left: 0
  transition: z-index 1s

  .backdrop
    background-color: black
    width: 100%
    height: 100vh
    transition: opacity 0.5s ease-in-out

  .sidenav-inner
    position: absolute
    text-align: right
    top: 0
    transition: top 0.5s ease-in-out
    background-color: #1B1B1B
    width: 100%

    .logo
      width: 100%
      display: flex
      justify-content: center

      img
        width: 103px

  .section
    margin-top: 64px

    .subtle
      font-size: 16px
      line-height: 1.5
      color: #989898
      margin: 0 0 16px 0

    h6
      margin: 0 0 12px 0
      font-size: 22px
      line-height: 1.5
      color: #000000
      font-weight: 400

.sidenav.inactive
  z-index: -1

  .backdrop
    opacity: 0


  .sidenav-inner
    top: -75%


.sidenav.active
  z-index: 48

  .backdrop
    opacity: 0.6


  .sidenav-inner
    top: 0


