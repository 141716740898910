.topic-bought-list-page
  .columns-1200
    max-width: 1200px
    margin-right: auto
    margin-left: auto

  &._2-col
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex

  .panel-base
    margin-right: 12px
    margin-left: 12px
    padding: 40px
    border-style: solid
    border-width: 1px
    border-color: #4b4b4b
    border-radius: 24px
    background-color: #2d2d2d
    box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.3)
    -webkit-transition: all 200ms ease
    transition: all 200ms ease

    &.flex-grow
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

    &.swap-position.order-info-panel
      max-width: 390px
      min-width: 390px
      -ms-flex-order: 1
      order: 1
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

    &.mb40
      margin-bottom: 40px

  .show-title-wrap
    margin-bottom: 24px

  .h1
    margin-top: 0
    margin-bottom: 0
    border-style: none
    border-width: 1px
    border-color: #fff
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 40px
    line-height: 125%

    &.mb16
      margin-top: 0
      margin-bottom: 16px
      margin-left: 0

  .show-tag-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    max-height: 20px
    margin-right: auto
    margin-left: 12px
    grid-auto-columns: 1fr
    -ms-grid-columns: 1fr 1fr
    grid-template-columns: 1fr 1fr
    -ms-grid-rows: auto auto
    grid-template-rows: auto auto

  .tag
    display: block
    margin-right: 4px
    margin-bottom: 0
    margin-left: 4px
    padding: 3.5px 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-style: solid
    border-width: 1px
    border-color: #fff
    border-radius: 4px
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 12px
    line-height: 140%

    &.filled
      -webkit-align-self: flex-start
      align-self: flex-start
      border-style: none
      background-color: #4b4b4b

      &.ml0
        margin-right: 0
        margin-bottom: 12px
        margin-left: 0

  .mb16
    margin-bottom: 16px

  .show-content-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 24px
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -webkit-flex-direction: row
    -ms-flex-direction: row
    flex-direction: row
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center

  .show-cover
    overflow: hidden
    margin-right: 40px
    -webkit-align-self: flex-start
    align-self: flex-start
    -ms-flex-order: 0
    order: 0
    -webkit-box-flex: 0
    -webkit-flex: 0 0 auto
    -ms-flex: 0 0 auto
    flex: 0 0 auto
    border-radius: 24px
    box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.2)

  .show-info
    -webkit-box-flex: 0
    -webkit-flex: 0 auto
    -ms-flex: 0 auto
    flex: 0 auto

  .podcaster-info-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 24px
    padding: 16px
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    border-style: solid
    border-width: 2px
    border-color: #4b4b4b
    border-radius: 12px

  .podcaster-photo
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 56px
    height: 56px
    margin-right: 12px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    border-style: solid
    border-width: 1px
    border-color: #ffdd15
    border-radius: 21px

    &.large-ring
      width: 120px
      height: 120px
      margin-right: 24px
      border-width: 2px
      border-radius: 45px

  .image
    overflow: hidden
    width: 540px
    height: 405px
    border-radius: 24px
    -o-object-fit: cover
    object-fit: cover
    -o-object-position: 50% 0
    object-position: 50% 0

  .image-2
    width: 48px
    height: 48px
    border-radius: 16px

  .section
    height: 120px

  .h5
    margin-top: 0
    margin-bottom: 16px
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 18px
    line-height: 140%

  .text-link
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    font-family: Roboto, sans-serif
    color: #15c7ff
    line-height: 140%
    font-weight: 700
    text-decoration: none

    &:hover
      text-decoration: underline

  .mb4
    margin-bottom: 4px

  .button-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 100%

  .button
    padding: 15px 24px
    -webkit-align-self: flex-end
    align-self: flex-end
    border-radius: 12px
    background-color: #ffdd15
    -webkit-transition: all 200ms ease
    transition: all 200ms ease
    font-family: Roboto, sans-serif
    color: #111
    font-size: 16px
    line-height: 112.5%
    font-weight: 700
    text-align: center

    &:hover
      background-color: rgba(255, 221, 21, 0.8)

    &.button-secondary
      border-style: solid
      border-width: 1px
      border-color: #fff
      background-color: transparent
      color: #fff

      &:hover
        background-color: hsla(0, 0%, 100%, 0.05)

    &.mr12
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex

    &.full-width
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-flex-wrap: wrap
      -ms-flex-wrap: wrap
      flex-wrap: wrap
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1
      font-style: normal
      text-align: center

      &.mr12
        -webkit-box-pack: center
        -webkit-justify-content: center
        -ms-flex-pack: center
        justify-content: center
        font-family: Roboto, sans-serif

      &.mt36
        margin-top: 36px

      &.mt24
        margin-top: 24px

        &.mb8
          margin-bottom: 8px

      &.mb8
        margin-bottom: 8px

    &.small
      padding: 8px 12px
      -webkit-align-self: center
      -ms-grid-row-align: center
      align-self: center
      border-radius: 8px
      font-size: 14px

      &.mr8
        margin-right: 8px

    &.coupon-button
      margin-right: 0
      margin-bottom: 10px
      margin-left: 6px
      -webkit-align-self: flex-start
      align-self: flex-start

  .button-text
    margin-right: 4px
    margin-bottom: 0
    color: #fff
    font-size: 16px
    line-height: 112.5%
    font-weight: 700

  .link-block
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    padding: 14px 24px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    -webkit-align-self: center
    align-self: center
    border-style: solid
    border-width: 1px
    border-color: #fff
    border-radius: 12px
    text-decoration: none

    &.button-scondary
      -webkit-transition: all 200ms ease
      transition: all 200ms ease

      &:hover
        background-color: hsla(0, 0%, 100%, 0.05)

  .icon-box-20
    width: 20px
    height: 20px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center

    &.mr4
      margin-right: 4px

  .mr12
    margin-right: 12px

  .mb24
    margin-bottom: 24px

  .policy-text
    width: 100%
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #ccc
    font-size: 12px
    line-height: 160%

  .show-price-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 24px
    padding: 16px
    -webkit-box-orient: vertical
    -webkit-box-direction: normal
    -webkit-flex-direction: column
    -ms-flex-direction: column
    flex-direction: column
    -webkit-box-align: start
    -webkit-align-items: flex-start
    -ms-flex-align: start
    align-items: flex-start
    border-style: solid
    border-width: 2px
    border-color: #4b4b4b
    border-radius: 12px

  .countdown-text
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #fff
    line-height: 140%

  .show-price-info
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 8px
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    -webkit-box-flex: 1
    -webkit-flex: 1
    -ms-flex: 1
    flex: 1

  .special-price
    margin-right: 0

    &.mobile
      margin-right: 16px

  .normal-price
    -webkit-box-flex: 0
    -webkit-flex: 0 auto
    -ms-flex: 0 auto
    flex: 0 auto

  .price-label
    margin-top: 0
    margin-bottom: 4px
    font-family: Roboto, sans-serif
    color: #fff
    line-height: 140%

  .show-price
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #ffdd15
    font-size: 28px
    line-height: 140%
    font-weight: 700

    &.normar-price-text
      color: #909090
      font-weight: 400
      text-decoration: line-through

    &.mobile
      font-size: 18px

  .div
    width: 1px
    height: 32px
    margin-right: 24px
    margin-left: 24px
    background-color: #4b4b4b

  .discount-tag
    margin-bottom: 0
    padding: 6px 8px
    -webkit-align-self: flex-start
    align-self: flex-start
    -ms-flex-order: 1
    order: 1
    -webkit-box-flex: 0
    -webkit-flex: 0 0 auto
    -ms-flex: 0 0 auto
    flex: 0 0 auto
    border-radius: 4px
    background-color: #d40f14
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 12px
    line-height: 112.5%
    font-weight: 700
    text-align: left
    text-decoration: none
    text-transform: none

  .price-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 100%
    -webkit-box-align: start
    -webkit-align-items: flex-start
    -ms-flex-align: start
    align-items: flex-start
    -webkit-box-flex: 0
    -webkit-flex: 0 auto
    -ms-flex: 0 auto
    flex: 0 auto

  .body-2
    background-color: #464646
    text-align: center

  .container-1201
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex

  .refund-link
    color: #15c7ff
    font-weight: 700

    &:hover
      text-decoration: underline

  .contract-link
    color: #fff
    font-weight: 700

    &:hover
      text-decoration: underline

  .section-basic
    margin-top: 0
    margin-bottom: 24px

    &.mt108
      margin-top: 108px
      text-align: left

      &.ml12
        margin-left: 12px

  .funding-progress-bg
    overflow: visible
    height: 16px
    border-radius: 100px
    background-color: #4b4b4b

  .progress-info
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 12px
    -webkit-box-pack: justify
    -webkit-justify-content: space-between
    -ms-flex-pack: justify
    justify-content: space-between

  .funding-amount-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-align-self: center
    align-self: center

  .funding-percentage-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-align-self: flex-end
    align-self: flex-end

  .order-title
    margin-right: 8px
    margin-bottom: 0
    -webkit-align-self: flex-end
    align-self: flex-end
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 16px
    line-height: 140%
    font-weight: 700

    &.mr0
      margin-right: 0

  .progress-order-number
    margin-bottom: 0
    -webkit-align-self: flex-end
    align-self: flex-end
    font-family: Roboto, sans-serif
    color: #ffdd15
    font-size: 24px
    line-height: 100%
    font-weight: 700

  .progress-goal-number
    margin-right: 8px
    margin-bottom: 0
    -webkit-align-self: flex-end
    align-self: flex-end
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 20px
    line-height: 112%
    font-weight: 400

  .progress-value
    width: 30%
    height: 100%
    border-radius: 100px
    background-image: -webkit-gradient(linear, left top, right top, from(#ffdd15), to(#ffa115))
    background-image: linear-gradient(90deg, #ffdd15, #ffa115)

  .image-3
    width: 100%
    height: 100%

  .progress-achieve-number
    margin-bottom: 0
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    font-family: Roboto, sans-serif
    color: #ffdd15
    font-size: 28px
    line-height: 100%
    font-weight: 700

  .achieve-title
    margin-right: 4px
    margin-bottom: 0
    -webkit-align-self: flex-end
    align-self: flex-end
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 16px
    line-height: 140%
    font-weight: 700

  .section-title-wrap
    width: 100%
    margin-bottom: 16px

    &.mt36
      margin-top: 36px

      &.mt56
        margin-top: 56px

    &.mt56
      margin-top: 56px

  .section-title
    margin-top: 0
    margin-bottom: 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 24px
    line-height: 140%

    &.mb0
      margin-right: 4px
      margin-bottom: 0

    &.sku-title
      max-width: 310px
      min-width: 310px
      margin-bottom: 23px

  .section-title-underline
    width: 100%
    height: 1px
    background-color: #4b4b4b

  .text-link-large
    display: block
    font-family: Roboto, sans-serif
    color: #15c7ff
    font-size: 14px
    line-height: 140%
    font-weight: 700
    text-decoration: none

    &:hover
      text-decoration: underline

  .section-body-text
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #ccc
    font-size: 16px
    line-height: 160%

    &.mb16
      margin-bottom: 16px

  .sub-button-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-flex: 0
    -webkit-flex: 0 auto
    -ms-flex: 0 auto
    flex: 0 auto

  .yellow-text, .text-span
    color: #ffdd15

  .order-num-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-align-self: flex-end
    align-self: flex-end
    -webkit-box-flex: 0
    -webkit-flex: 0 auto
    -ms-flex: 0 auto
    flex: 0 auto

  .prepare-title-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 8px
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center

  .podcaster-info-detail-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 24px
    padding: 0
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    border-style: none
    border-width: 2px
    border-color: #4b4b4b
    border-radius: 0

  .podcaster-photo-detail
    width: 102px
    height: 102px
    border-radius: 34px

  .podcaster-name
    margin-top: 0
    margin-bottom: 16px
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 28px
    line-height: 140%

  .social-link-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex

  .social-link-box
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 48px
    height: 48px
    margin-right: 8px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    border-radius: 12px
    background-color: #4b4b4b
    -webkit-transition: all 200ms ease
    transition: all 200ms ease

    &:hover
      background-color: #6f6f6f

  .social-logo
    width: 24px
    height: 24px

  .progress-prompt-text
    width: 100%
    margin-bottom: 0
    padding: 12px 24px
    border-radius: 12px
    background-color: #4b4b4b
    font-family: Roboto, sans-serif
    color: #fff
    line-height: 140%

  .bottom-bar
    position: fixed
    left: 0
    top: auto
    right: 0
    bottom: 0
    display: none
    padding: 12px 16px
    border-style: solid none none
    border-width: 1px
    border-color: #6f6f6f
    background-color: #4b4b4b

  .menu-button
    color: #fff
    font-weight: 700

  .h3
    margin-top: 0
    margin-bottom: 16px
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 28px
    line-height: 125%

  .h2
    margin-top: 0
    margin-bottom: 16px
    font-family: Roboto, sans-serif
    color: #fff
    line-height: 125%

  .body-text
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #ccc
    font-size: 16px
    line-height: 160%
    font-weight: 400

    &.mb8
      margin-bottom: 8px

    &.amount-text
      text-align: right

  .utility-page-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 100vw
    height: 100vh
    max-height: 100%
    max-width: 100%
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center

  .utility-page-content
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 260px
    -webkit-box-orient: vertical
    -webkit-box-direction: normal
    -webkit-flex-direction: column
    -ms-flex-direction: column
    flex-direction: column
    text-align: center

  .utility-page-form
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-orient: vertical
    -webkit-box-direction: normal
    -webkit-flex-direction: column
    -ms-flex-direction: column
    flex-direction: column
    -webkit-box-align: stretch
    -webkit-align-items: stretch
    -ms-flex-align: stretch
    align-items: stretch

  .body-3
    background-color: #111

  .radio-button
    margin-top: 10px
    font-size: 14px
    font-weight: 400

  .radio-button-field
    -o-object-fit: fill
    object-fit: fill

  .div-block
    -webkit-column-count: auto
    column-count: auto

    &.order-info-div-block
      margin-bottom: 8px
      -webkit-column-count: 2
      column-count: 2

      &.order-amount-div-block
        margin-top: 22px
        margin-bottom: 22px
        padding-top: 0

  .body-text-copy
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #ccc
    font-size: 16px
    line-height: 160%
    font-weight: 400

    &.mb8
      margin-bottom: 8px

    &.amount-text
      text-align: right

  .order-body-text-right
    color: #fff
    font-size: 16px
    text-align: right

    &.order-amount-text
      font-size: 24px

    &.order-discount-text
      color: #ffdd15

  .order-body-text
    color: #fff
    font-size: 16px

  .text-field
    -o-object-fit: fill
    object-fit: fill

    &.coupon-text-field
      position: static
      display: block
      overflow: visible
      margin-right: auto
      margin-left: auto
      float: left
      -webkit-align-self: center
      -ms-grid-row-align: center
      align-self: center
      -ms-flex-order: -1
      order: -1
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1
      -o-object-fit: fill
      object-fit: fill

  .coupon-label
    margin-top: 26px
    margin-bottom: 8px
    padding-top: 0
    color: #fff
    font-size: 16px
    font-weight: 400

  .div-block-2
    float: none

  .div-block-3
    position: static
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    float: none
    clear: none
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -webkit-flex-direction: row
    -ms-flex-direction: row
    flex-direction: row
    -webkit-flex-wrap: nowrap
    -ms-flex-wrap: nowrap
    flex-wrap: nowrap
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    text-decoration: none

  .section-2
    padding-top: 108px
    margin-bottom: 24px

  .container-1200
    max-width: 1200px
    -o-object-fit: fill
    object-fit: fill

  .text-block
    max-width: 97px

  .text-block-2
    max-width: 98px
    color: #909090

  .tag-2
    display: block
    max-width: 98px
    margin-right: auto
    margin-bottom: 0
    margin-left: 12px
    padding-top: 3.5px
    padding-right: 8px
    padding-bottom: 3.5px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border: 1px solid transparent
    border-radius: 0
    font-family: Roboto, sans-serif
    color: #909090
    font-size: 12px
    line-height: 140%

  .tag-3
    display: block
    margin-right: 4px
    margin-bottom: 0
    margin-left: 0
    padding: 3.5px 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-style: solid
    border-width: 1px
    border-color: #fff
    border-radius: 4px
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 12px
    line-height: 140%

    &.filled
      -webkit-align-self: flex-start
      align-self: flex-start
      border-style: none
      background-color: #4b4b4b

      &.ml0
        margin-right: 0
        margin-bottom: 12px
        margin-left: 0

    &.yellow
      border-color: #ffdd15
      color: #ffdd15
      margin-right: 0
      margin-left: 4px

    &.white
      margin-right: 0
      margin-left: 4px

  .container-1202
    max-width: 1200px
    margin-top: 26px

  .panel-base-2
    margin-right: auto
    margin-left: 12px
    padding: 40px
    border-style: solid
    border-width: 2px
    border-color: #4b4b4b
    border-radius: 8px
    background-color: #2d2d2d
    box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.3)
    -webkit-transition: all 200ms ease
    transition: all 200ms ease

  .episode-title
    margin-top: 0
    margin-bottom: auto
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 32px
    line-height: 140%

    &.mt120
      margin-top: 120px

  .section-title-underline-2
    width: 192px
    height: 1px
    margin-right: auto
    margin-left: auto
    background-color: #4b4b4b
    text-align: left

  .section-body-text-2
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #ccc
    font-size: 16px
    line-height: 160%

    &.mb16
      margin-bottom: 16px

  .text-link-2
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    font-family: Roboto, sans-serif
    color: #15c7ff
    line-height: 140%
    font-weight: 700
    text-decoration: none

    &:hover
      text-decoration: underline

  .episode-list-panel
    overflow: hidden
    max-height: 480px
    margin-right: 12px
    margin-bottom: 0
    margin-left: 0
    padding: 24px
    border-style: solid
    border-width: 2px
    border-color: #4b4b4b
    border-radius: 8px
    background-color: #2d2d2d
    box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.3)
    -webkit-transition: all 200ms ease
    transition: all 200ms ease

  .play-speed-box-2
    margin-bottom: 16px
    padding: 9px 8px
    border-style: solid
    border-width: 1px
    border-color: #ececec
    border-radius: 8px
    background-color: #ececec
    -webkit-transition: all 200ms ease
    transition: all 200ms ease
    text-decoration: none

    &:hover
      border-style: solid
      border-width: 1px
      border-color: #909090
      background-color: #ececec

  .paragraph-4
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #111
    font-size: 16px
    line-height: 140%
    font-weight: 700
    text-decoration: none

  .section-title-2
    margin-top: 0
    margin-bottom: 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 24px
    line-height: 140%

  .text-link-large-2
    display: block
    font-family: Roboto, sans-serif
    color: #15c7ff
    font-size: 14px
    line-height: 140%
    font-weight: 700
    text-decoration: none

    &:hover
      text-decoration: underline

  .section-3
    height: 120px

  .heading
    margin-top: 0
    margin-bottom: 12px
    color: #fff
    font-size: 20px

  .show-tag-wrap-2
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-right: auto
    margin-left: auto
    -webkit-box-align: end
    -webkit-align-items: flex-end
    -ms-flex-align: end
    align-items: flex-end

  .episode
    margin-bottom: 12px
    padding: 16px
    border-style: solid
    border-width: 2px
    border-color: #4b4b4b
    border-radius: 4px

    &:hover
      background-color: #111

  .heading-2
    margin-top: auto
    margin-bottom: 8px
    color: #fff
    font-size: 16px

  .filled-whute
    display: block
    margin-right: 8px
    margin-bottom: 0
    margin-left: 4px
    padding: 3.5px 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-style: none
    border-width: 1px
    border-color: #fff
    border-radius: 4px
    background-color: #fff
    font-family: Roboto, sans-serif
    color: #000
    font-size: 12px
    line-height: 140%
    font-weight: 700

    &.filled
      -webkit-align-self: flex-start
      align-self: flex-start
      border-style: none
      background-color: #4b4b4b

      &.ml0
        margin-right: 0
        margin-bottom: 12px
        margin-left: 0

    &.yellow
      border-color: #ffdd15
      color: #ffdd15

  .body-4
    background-color: #111

  .menu
    display: block
    max-width: 240px
    margin-right: auto
    margin-left: auto
    padding: 16px 12px
    border-radius: 12px
    background-color: #2d2d2d
    text-align: left

  .div-block-5
    height: 46px
    padding: 12px 16px

  .menu-list
    margin-top: 8px
    padding-left: 0

  .div-block-6
    height: 44px
    padding: 12px 16px
    border-radius: 8px

    &:hover
      background-color: #4b4b4b

  .section-4
    margin-top: 108px

  .sticky-nav-2
    position: -webkit-sticky
    position: sticky
    top: 64px
    z-index: 1
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    height: 56px
    padding: 20px
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    background-color: #2d2d2d
    box-shadow: 0 8px 0 0 rgba(0, 0, 0, 0.2)

  .section-5
    padding-top: 108px
    margin-bottom: 24px

  .container-1203
    max-width: 1200px
    margin-bottom: 16px
    padding-top: 20px
    padding-bottom: 20px
    padding-left: 12px

  .text-block-3
    color: #fff
    font-size: 32px
    font-weight: 700

  .columns-1202
    max-width: 1200px
    margin-right: auto
    margin-left: auto
    text-align: center

  .div-block-7
    height: 380px
    margin-bottom: 32px
    transition: all 0.3s ease-in-out
    margin-left: 0
    padding: 24px
    border-radius: 8px
    border-style: solid
    border-width: 1px
    border-color: #2d2d2d
    background-color: #2d2d2d

    &:hover
      border-color: #6f6f6f

  .image-4
    width: 100%
    object-fit: cover
    height: 175.5px
    margin-bottom: 16px
    border-radius: 8px

  .podcaster
    display: block
    width: 32px
    height: 32px
    margin-right: 0
    margin-left: 0
    border-radius: 12px
    text-align: left

  .text-block-4
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-right: auto
    margin-left: 12px
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -webkit-flex-direction: row
    -ms-flex-direction: row
    flex-direction: row
    -webkit-box-pack: start
    -webkit-justify-content: flex-start
    -ms-flex-pack: start
    justify-content: flex-start
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    -webkit-align-self: center
    align-self: center
    color: #ccc
    font-weight: 700
    text-align: left

  .div-block-8
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 8px
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -webkit-flex-direction: row
    -ms-flex-direction: row
    flex-direction: row
    -webkit-box-align: end
    -webkit-align-items: flex-end
    -ms-flex-align: end
    align-items: flex-end

  .text-block-5
    color: #fff
    font-size: 20px
    line-height: 140%
    font-weight: 700
    text-align: left
    text-transform: none

    &:hover
      text-decoration: underline

  .div-block-9
    height: 68.5px
    margin-bottom: 8px

  .container-1204
    max-width: 1200px
    margin-bottom: 24px
    padding-right: 12px
    padding-left: 12px

  .column-2, .column-3, .column-4, .column-5
    padding-right: 12px
    padding-left: 12px

  .section-6
    height: 120px

  .tag-5
    display: block
    margin-right: 4px
    margin-bottom: 0
    margin-left: 4px
    padding: 3.5px 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-style: solid
    border-width: 1px
    border-color: #fff
    border-radius: 4px
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 12px
    line-height: 140%

    &.filled
      -webkit-align-self: flex-start
      align-self: flex-start
      border-style: none
      background-color: #4b4b4b

      &.ml0
        margin-right: 0
        margin-bottom: 12px
        margin-left: 0

    &.yellow
      border-color: #ffdd15
      color: #ffdd15
      margin-right: 0
      margin-left: 4px

    &.white
      margin-right: 0
      margin-left: 4px

  .line
    height: 1px
    margin-right: auto
    margin-left: auto
    background-color: #4b4b4b
    text-align: left

  @media screen and (max-width: 991px)
    .columns-1200._2-col
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .panel-base.swap-position
      margin-bottom: 24px
      -ms-flex-order: -1
      order: -1

      &.order-info-panel
        max-width: 744px
        margin-top: 16px

    .show-content-wrap
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .show-cover
      width: 100%
      height: 100%
      margin-bottom: 24px

    .image
      width: 100%
      height: 100%

    .section-body-text
      font-size: 16px

    .menu-button
      padding: 6px
      border-radius: 8px
      background-color: #fff

    .icon
      color: #111

    .nav-menu-2
      padding: 12px 16px
      background-color: #111

    .panel-base-2
      overflow: visible

    .section-body-text-2
      font-size: 16px

    .columns-1201
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-orient: horizontal
      -webkit-box-direction: normal
      -webkit-flex-direction: row
      -ms-flex-direction: row
      flex-direction: row
      -webkit-flex-wrap: wrap
      -ms-flex-wrap: wrap
      flex-wrap: wrap

    .column
      align-self: auto
      order: -1
      -webkit-box-flex: 0
      -webkit-flex: 0 auto
      -ms-flex: 0 auto
      flex: 0 auto

  @media screen and (max-width: 767px)
    .columns-1200._2-col
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .panel-base
      padding: 32px
      -ms-flex-order: 0
      order: 0
      border-radius: 12px

      &.flex-grow
        margin-bottom: 0
        -ms-flex-order: 1
        order: 1

      &.swap-position
        margin-bottom: 16px

        &.order-info-panel
          max-width: 568px
          -webkit-box-flex: 0
          -webkit-flex: 0 auto
          -ms-flex: 0 auto
          flex: 0 auto

    .h1
      font-size: 32px

    .show-content-wrap
      margin-bottom: 16px

    .podcaster-info-wrap
      margin-bottom: 16px
      padding: 12px

    .button-wrap
      &.mb24
        margin-bottom: 16px
        -webkit-box-orient: vertical
        -webkit-box-direction: normal
        -webkit-flex-direction: column
        -ms-flex-direction: column
        flex-direction: column

      &.mb16
        -webkit-box-orient: vertical
        -webkit-box-direction: normal
        -webkit-flex-direction: column
        -ms-flex-direction: column
        flex-direction: column

    .button
      &.button-secondary
        -webkit-box-flex: 0
        -webkit-flex: 0 auto
        -ms-flex: 0 auto
        flex: 0 auto

        &.mr12
          -webkit-box-pack: center
          -webkit-justify-content: center
          -ms-flex-pack: center
          justify-content: center
          -webkit-box-flex: 1
          -webkit-flex: 1
          -ms-flex: 1
          flex: 1

      &.full-width.mr12
        width: 100%
        margin-right: 0
        margin-bottom: 12px

    .link-block.button-scondary
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

    .show-price-wrap
      margin-bottom: 16px
      padding: 12px

    .div
      margin-right: 16px
      margin-left: 16px

    .discount-tag
      padding: 4px 6px
      border-radius: 4px
      font-size: 12px
      line-height: 140%

    .section-basic
      margin-bottom: 16px

    .section-body-text
      font-size: 16px

    .podcaster-info-detail-wrap
      margin-bottom: 16px
      padding: 12px

    .bottom-bar
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      background-color: #2d2d2d

    .sticky-nav
      position: relative

    .hoost-logo
      padding-left: 0

    .sub-nav-button-wrap
      display: none

    .menu-button
      overflow: hidden
      padding: 0
      border-radius: 8px
      background-color: #111

      &.w--open
        overflow: hidden
        border-radius: 8px
        background-color: #ececec

    .icon
      overflow: hidden
      padding: 6px
      border-radius: 8px
      background-color: #ececec
      color: #111

      &:hover
        background-color: hsla(0, 0%, 100%, 0.8)

    .nav-menu-2
      padding: 12px 16px
      background-color: #111

    .panel-base-2
      padding: 32px
      -ms-flex-order: 0
      order: 0
      border-radius: 12px

    .section-body-text-2
      font-size: 16px

    .episode-list-panel
      padding: 32px
      -ms-flex-order: 0
      order: 0
      border-radius: 12px

    .play-speed-box-2
      margin-right: 16px
      margin-bottom: 0
      -webkit-align-self: center
      -ms-grid-row-align: center
      align-self: center
      -webkit-box-flex: 0
      -webkit-flex: 0 auto
      -ms-flex: 0 auto
      flex: 0 auto

    .sticky-nav-2
      position: relative

  @media screen and (max-width: 479px)
    .columns-1200._2-col
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .panel-base
      margin-right: 8px
      margin-left: 8px
      padding: 24px

      &.flex-grow
        margin-bottom: 0

      &.swap-position.order-info-panel
        min-width: 304px
        margin-top: 16px

    .show-title-wrap
      margin-bottom: 16px

    .h1
      font-size: 24px

      &.mb16
        margin-bottom: 8px

    .show-tag-wrap
      -webkit-flex-wrap: wrap
      -ms-flex-wrap: wrap
      flex-wrap: wrap

    .tag
      margin-top: 4px
      margin-right: 4px
      margin-bottom: 4px

    .show-cover
      border-radius: 12px

    .show-info
      width: 100%

    .podcaster-photo.large-ring
      margin-right: 0
      margin-bottom: 16px

    .image
      border-radius: 12px

    .button.comment-button
      width: 100%

    .show-price
      font-size: 24px

    .price-wrap
      margin-bottom: 12px
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .section-basic
      margin-bottom: 16px

    .funding-amount-wrap
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column
      -webkit-box-align: start
      -webkit-align-items: flex-start
      -ms-flex-align: start
      align-items: flex-start

    .funding-percentage-wrap
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .order-title
      margin-right: 0
      margin-bottom: 4px
      -webkit-align-self: flex-start
      align-self: flex-start
      font-size: 12px

      &.mr0
        margin-bottom: 2px
        -webkit-align-self: flex-end
        align-self: flex-end

    .progress-goal-number
      margin-right: 4px

    .progress-achieve-number
      font-size: 24px

    .achieve-title
      margin-right: 0
      margin-bottom: 4px
      font-size: 12px

    .section-title
      font-size: 20px

    .order-num-wrap
      -webkit-box-align: end
      -webkit-align-items: flex-end
      -ms-flex-align: end
      align-items: flex-end
      -webkit-align-self: flex-start
      align-self: flex-start

    .podcaster-info-detail-wrap
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .podcaster-name
      margin-bottom: 12px
      text-align: center

    .bottom-bar
      background-color: #2d2d2d

    .sticky-nav
      padding: 0

    .nav-logo-link
      border-top: 1px solid #c4c4c4

    .nav-link
      padding-top: 10px
      padding-bottom: 10px
      border-top: 1px solid #c4c4c4

    .hoost-logo
      padding-left: 0

    .nav-container
      -webkit-box-pack: start
      -webkit-justify-content: flex-start
      -ms-flex-pack: start
      justify-content: flex-start

    .nav-sub-menu
      -webkit-box-flex: 0
      -webkit-flex: 0 0 auto
      -ms-flex: 0 0 auto
      flex: 0 0 auto

    .sub-menu-wrap
      overflow: hidden
      -webkit-flex-wrap: nowrap
      -ms-flex-wrap: nowrap
      flex-wrap: nowrap
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

    .avatar-image
      width: 100%
      height: 100%

    .tag-2, .tag-3
      margin-top: 4px
      margin-right: 4px
      margin-bottom: 4px

    .panel-base-2
      overflow: visible
      margin-right: 8px
      margin-left: 8px
      padding: 24px

    .episode-title
      font-size: 20px

    .episode-list-panel
      margin-right: 8px
      margin-left: 8px
      padding: 24px

    .section-title-2
      font-size: 20px

    .button-2.comment-button
      width: 100%

    .comment-divider-2
      margin-left: 0

    .show-tag-wrap-2
      -webkit-flex-wrap: wrap
      -ms-flex-wrap: wrap
      flex-wrap: wrap

    .filled-whute
      margin-top: 4px
      margin-right: 4px
      margin-bottom: 4px

    .sticky-nav-2
      padding: 0

    .tag-5
      margin-top: 4px
      margin-right: 4px
      margin-bottom: 4px
