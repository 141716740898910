@import "Colors"
@import "Width"

.topic-detail
  background-color: #111

  .special-discount-info
    margin-bottom: 24px
    border-radius: 12px
    padding: 12px
    display: flex
    background: rgb(175, 41, 151)
    background: linear-gradient(90deg, rgba(175, 41, 151, 1) 0%, rgba(80, 155, 245, 1) 100%)

    .special-discounts-title
      font-size: 16px
      @media (max-width: 747px)
        font-size: 14px

    .special-discounts-content
      font-size: 14px
      @media (max-width: 747px)
        font-size: 12px

    p
      color: #fff
      @media (max-width: 747px)
        font-weight: bold

    img
      margin-right: 8px

  .course-contents

    .image-5-copy
      width: 20px
      height: 20px
      margin-right: 10px
      margin-left: 0

    .text-block-copy
      font-size: 16px
      font-family: Roboto, sans-serif
      color: #ccc

    .div-block
      display: flex
      margin-bottom: 12px
      align-items: flex-start

    .syllabus
      padding-top: 24px
      margin-bottom: 24px

    .episode
      display: flex
      margin-bottom: 11px
      padding: 16px 24px
      align-items: center
      border: 1px solid #4b4b4b
      border-radius: 12px
      background-color: #383838
      box-shadow: 1px 1px 3px 0 #1f1f1f

    .episode:hover
      background-color: #404040
      color: #000

    .gray-episode-title
      margin-bottom: 10px
      color: #7E7E7E
      font-size: 16px

    .white-episode-title
      margin-bottom: 10px
      color: #FFFFFF
      font-size: 16px

    .yellow-episode-title
      margin-bottom: 10px
      color: #FFDD15
      font-size: 16px

    .paid-episode-tag
      display: block
      margin-right: 4px
      margin-bottom: 0
      margin-left: 4px
      padding: 3.5px 8px
      align-self: center
      border-style: none
      border-width: 1px
      border-color: #ccc
      border-radius: 4px
      background-color: #4e4e4e
      font-family: Roboto, sans-serif
      color: #ccc
      font-size: 12px
      line-height: 140%

      &.filled
        -webkit-align-self: flex-start
        align-self: flex-start
        border-style: none
        background-color: #4b4b4b

        &.ml0
          margin-right: 0
          margin-bottom: 12px
          margin-left: 0

    .paid-episode-preparing-tag
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      margin-right: 4px
      margin-bottom: 0
      margin-left: 4px
      padding: 3.5px 8px
      -webkit-align-self: center
      align-self: center
      border-style: solid
      border-width: 1px
      border-color: #ccc
      border-radius: 4px
      font-family: Roboto, sans-serif
      color: #ccc
      font-size: 12px
      line-height: 140%

      &.filled
        -webkit-align-self: flex-start
        align-self: flex-start
        border-style: none
        background-color: #4b4b4b

        &.ml0
          margin-right: 0
          margin-bottom: 12px
          margin-left: 0

    .episode-info
      display: block
      margin-right: 24px
      flex-direction: column
      align-items: flex-start
      flex: 1


    .free-tag
      display: block
      margin-right: 8px
      margin-bottom: 0
      margin-left: 0
      padding: 3.5px 8px
      align-self: center
      border-style: none
      border-width: 1px
      border-color: #ffdd15
      border-radius: 4px
      background-color: #555
      font-family: Roboto, sans-serif
      color: #ffdd15
      font-size: 12px
      line-height: 140%

    .white-episode-time
      display: block
      margin-right: 8px
      margin-bottom: 0
      margin-left: 0
      padding: 3.5px 8px
      align-self: center
      border-style: none
      border-width: 1px
      border-color: #ffdd15
      border-radius: 4px
      background-color: #555
      font-family: Roboto, sans-serif
      color: #ffffff
      font-size: 12px
      line-height: 140%

    .yellow-episode-time
      display: block
      margin-right: 8px
      margin-bottom: 0
      margin-left: 0
      padding: 3.5px 8px
      align-self: center
      border-style: none
      border-width: 1px
      border-color: #ffdd15
      border-radius: 4px
      background-color: #555
      font-family: Roboto, sans-serif
      color: #ffdd15
      font-size: 12px
      line-height: 140%

    .gray-episode-time
      display: block
      margin-right: 8px
      margin-bottom: 0
      margin-left: 0
      padding: 3.5px 8px
      align-self: center
      border-style: none
      border-width: 1px
      border-color: #ffdd15
      border-radius: 4px
      background-color: #555
      font-family: Roboto, sans-serif
      color: #ccc
      font-size: 12px
      line-height: 140%

    .image-6
      width: 16px
      height: 16px
      margin-right: 8px

    .div-block-2
      display: flex
      justify-content: flex-start
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

  .anchor
    display: block
    position: relative
    top: -148px
    visibility: hidden

  .container-1200
    max-width: 1200px

    &._2-col
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex

  .body
    background-color: #111

  .sku-list
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    list-style-type: disc

  .panel-base
    margin-right: 12px
    margin-left: 12px
    padding: 40px
    border-style: solid
    border-width: 1px
    border-color: #4b4b4b
    border-radius: 24px
    background-color: #2d2d2d
    box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.3)
    -webkit-transition: all 200ms ease
    transition: all 200ms ease

    &.full-width
      @media (min-width: 992px)
        width: 100%

    &.flex-grow
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

    &.mb40
      margin-bottom: 40px

  .show-title-wrap
    margin-bottom: 24px

  .h1
    margin-top: 0
    margin-bottom: 0
    border-style: none
    border-width: 1px
    border-color: #fff
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 40px
    line-height: 125%

  .show-tag-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-right: -4px
    margin-left: -4px

  .tag
    display: block
    margin-right: 4px
    margin-bottom: 0
    margin-left: 4px
    padding: 3.5px 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-style: solid
    border-width: 1px
    border-color: #fff
    border-radius: 4px
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 12px
    line-height: 140%

    &.filled
      -webkit-align-self: flex-start
      align-self: flex-start
      border-style: none
      background-color: #4b4b4b

      &.ml0
        margin-right: 0
        margin-bottom: 12px
        margin-left: 0

  .mb16
    margin-bottom: 16px
    @media (min-width: 1200px)
      width: 1094px
    @media (max-width: 1200px)
      width: 100%

  .show-content-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 24px
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -webkit-flex-direction: row
    -ms-flex-direction: row
    flex-direction: row
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center

  .show-cover
    margin-right: 40px
    -webkit-align-self: flex-start
    align-self: flex-start
    -webkit-box-ordinal-group: 1
    -ms-flex-order: 0
    order: 0
    -webkit-box-flex: 0
    -webkit-flex: 0 0 auto
    -ms-flex: 0 0 auto
    flex: 0 0 auto
    border-radius: 24px
    box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.2)

  .show-info
    -webkit-box-flex: 0
    -webkit-flex: 0 auto
    -ms-flex: 0 auto
    flex: 0 auto

  .podcaster-info-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 24px
    padding: 16px
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    border-style: solid
    border-width: 2px
    border-color: #4b4b4b
    border-radius: 12px

  .podcaster-photo
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 56px
    height: 56px
    margin-right: 12px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    border-style: solid
    border-width: 1px
    border-color: #ffdd15
    border-radius: 21px

    &.large-ring
      width: 120px
      height: 120px
      margin-right: 24px
      border-width: 2px
      border-radius: 45px

  .image
    width: 540px
    height: auto
    border-radius: 24px
    background-size: cover
    -o-object-fit: cover
    object-fit: cover
    overflow: hidden
    -o-object-position: 50% 0
    object-position: 50% 0

    iframe
      border-radius: 24px

  .image-2
    width: 48px
    height: 48px
    border-radius: 16px

  .section
    height: 120px

  .h5
    margin-top: 0
    margin-bottom: 16px
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 18px
    line-height: 140%

  .text-link
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    font-family: Roboto, sans-serif
    color: #15c7ff
    line-height: 140%
    font-weight: 700
    text-decoration: none

    &:hover
      text-decoration: underline

  .mb4
    margin-bottom: 4px

  .button-wrap
    display: flex
    width: 100%

  .button
    padding: 15px 24px
    -webkit-align-self: flex-end
    align-self: flex-end
    border-radius: 12px
    background-color: #ffdd15
    -webkit-transition: all 200ms ease
    transition: all 200ms ease
    font-family: Roboto, sans-serif
    color: #111
    font-size: 16px
    line-height: 112.5%
    font-weight: 700
    text-align: center

    &:hover
      background-color: rgba(255, 221, 21, 0.8)

    &.button-secondary
      border-style: solid
      border-width: 1px
      border-color: #fff
      background-color: transparent
      color: #fff

    &.button-disabled
      border-style: solid
      background-color: #B0B0B0
      color: #000

      &:hover
        background-color: #929292

    &.mr12
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex

    &.full-width
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

      &.mr12
        -webkit-box-pack: center
        -webkit-justify-content: center
        -ms-flex-pack: center
        justify-content: center
        font-family: Roboto, sans-serif

    &.small
      padding: 8px 12px
      -webkit-align-self: center
      -ms-grid-row-align: center
      align-self: center
      border-radius: 8px
      font-size: 14px

      &.mr8
        margin-right: 8px

  .button-text
    margin-right: 4px
    margin-bottom: 0
    color: #fff
    font-size: 16px
    line-height: 112.5%
    font-weight: 700

  .link-block
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    padding: 14px 24px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    -webkit-align-self: center
    align-self: center
    border-style: solid
    border-width: 1px
    border-color: #fff
    border-radius: 12px
    text-decoration: none

    &.button-scondary
      -webkit-transition: all 200ms ease
      transition: all 200ms ease

  .icon-box-20
    width: 20px
    height: 20px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center

    &.mr4
      margin-right: 4px

  .mr12
    margin-right: 12px

  .mb24
    margin-bottom: 24px

  .policy-text
    width: 100%
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #ccc
    font-size: 12px
    line-height: 160%

  .show-price-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 24px
    padding: 16px
    -webkit-box-orient: vertical
    -webkit-box-direction: normal
    -webkit-flex-direction: column
    -ms-flex-direction: column
    flex-direction: column
    -webkit-box-align: start
    -webkit-align-items: flex-start
    -ms-flex-align: start
    align-items: flex-start
    border-style: solid
    border-width: 2px
    border-color: #4b4b4b
    border-radius: 12px

  .countdown-text
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #fff
    line-height: 140%

  .show-price-info
    display: flex
    margin-bottom: 8px
    align-items: center

  .special-price
    margin-right: 0

    &.mobile
      margin-right: 16px

  .normal-price
    -webkit-box-flex: 0
    -webkit-flex: 0 auto
    -ms-flex: 0 auto
    flex: 0 auto

  .price-label
    margin-top: 0
    margin-bottom: 4px
    font-family: Roboto, sans-serif
    color: #fff
    line-height: 140%

  .show-price
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #ffdd15
    font-size: 28px
    line-height: 140%
    font-weight: 700

    &.normar-price-text
      color: #909090
      font-weight: 400
      text-decoration: line-through

    &.mobile
      font-size: 18px

  .div
    width: 1px
    height: 32px
    margin-right: 24px
    margin-left: 24px
    background-color: #4b4b4b

  .discount-tag
    margin-bottom: 0
    padding: 6px 8px
    -webkit-align-self: flex-start
    align-self: flex-start
    -webkit-box-ordinal-group: 2
    -ms-flex-order: 1
    order: 1
    -webkit-box-flex: 0
    -webkit-flex: 0 0 auto
    -ms-flex: 0 0 auto
    flex: 0 0 auto
    border-radius: 4px
    background-color: #d40f14
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 12px
    line-height: 112.5%
    font-weight: 700
    text-align: left
    text-decoration: none
    text-transform: none

  .price-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 100%
    -webkit-box-align: start
    -webkit-align-items: flex-start
    -ms-flex-align: start
    align-items: flex-start
    justify-content: space-between
    -webkit-box-flex: 0
    -webkit-flex: 0 auto
    -ms-flex: 0 auto
    flex: 0 auto

  .body-2
    background-color: #464646

  .container-1201
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex

  .refund-link
    color: #15c7ff
    font-weight: 700

    &:hover
      text-decoration: underline

  .contract-link
    color: #fff
    font-weight: 700

    &:hover
      text-decoration: underline

  .section-basic
    margin-bottom: 24px

    &.mt108
      margin-top: 108px

  .funding-progress-bg
    overflow: visible
    height: 16px
    border-radius: 100px
    background-color: #4b4b4b

  .progress-info
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 12px
    -webkit-box-pack: justify
    -webkit-justify-content: space-between
    -ms-flex-pack: justify
    justify-content: space-between

  .funding-amount-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-align-self: center
    align-self: center

  .funding-percentage-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-align-self: flex-end
    align-self: flex-end

  .order-title
    margin-right: 8px
    margin-bottom: 0
    -webkit-align-self: flex-end
    align-self: flex-end
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 16px
    line-height: 140%
    font-weight: 700

    @media (max-width: 479px)
      margin-right: 4px

    &.mr0
      margin-right: 0

  .progress-order-number
    margin-bottom: 0
    -webkit-align-self: flex-end
    align-self: flex-end
    font-family: Roboto, sans-serif
    color: #ffdd15
    font-size: 24px
    line-height: 100%
    font-weight: 700
    margin-right: 8px
    @media (max-width: 479px)
      margin-right: 4px

  .progress-goal-number
    margin-right: 8px
    margin-bottom: 0
    -webkit-align-self: flex-end
    align-self: flex-end
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 20px
    line-height: 112%
    font-weight: 400

  .progress-value
    height: 100%
    border-radius: 100px
    background-image: -webkit-gradient(linear, left top, right top, from(#ffdd15), to(#ffa115))
    background-image: linear-gradient(90deg, #ffdd15, #ffa115)

  .image-3
    width: 100%
    height: 100%

  .progress-achieve-number
    margin-bottom: 0
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    font-family: Roboto, sans-serif
    color: #ffdd15
    font-size: 28px
    line-height: 100%
    font-weight: 700

  .achieve-title
    margin-right: 4px
    margin-bottom: 0
    align-self: flex-end
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 16px
    line-height: 140%
    font-weight: 700

    @media (max-width: 479px)
      display: none

  .section-title-wrap
    width: 100%
    margin-bottom: 24px

  .section-title
    margin-top: 0
    margin-bottom: 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 28px
    line-height: 140%

    &.mb0
      margin-right: 4px
      margin-bottom: 0

  .section-title-underline
    width: 100%
    height: 1px
    background-color: #4b4b4b

  .text-link-large
    display: block
    font-family: Roboto, sans-serif
    color: #15c7ff
    font-size: 14px
    line-height: 140%
    font-weight: 700
    text-decoration: none

    &:hover
      text-decoration: underline

  .section-body-text
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 16px
    line-height: 160%

    a
      color: #ffdd15

    &.mb16
      margin-bottom: 16px

  .sub-button-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-flex: 0
    -webkit-flex: 0 auto
    -ms-flex: 0 auto
    flex: 0 auto

  .yellow-text, .text-span
    color: #ffdd15

  .order-num-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-align-self: flex-end
    align-self: flex-end
    -webkit-box-flex: 0
    -webkit-flex: 0 auto
    -ms-flex: 0 auto
    flex: 0 auto

  .prepare-title-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 8px
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center

  .funding-progress
    position: relative
    width: 100%
    min-width: 402px
    height: 120px

    .funding-progress-bar
      width: 70%
      left: 15%
      position: absolute
      top: 35px
      height: 4px
      background: #404040

    .funding-progress-bar-filled
      left: 15%
      position: absolute
      top: 35px
      height: 4px
      background: linear-gradient(89.98deg, #FFDD15 2.9%, #FFA115 98.69%)
      border-radius: 100px

    .funding-circle
      width: 30%
      position: absolute

      p
        text-align: center

      .funding-circle-name
        color: white

      .funding-circle-date
        color: #CCCCCC

      .outer-circle
        background: #939393
        border: 2px solid #2C2C2C

      .inner-circle
        background: #707070
        border: 2px solid #2C2C2C

      &.active
        .outer-circle
          background: #FFDD15
          border: 2px solid #2C2C2C

        .inner-circle
          background: #FFFFFF
          border: 2px solid #2C2C2C

        .funding-circle-name
          color: #FFDD15

    .circle1
      left: 0

    .circle2
      left: calc(50% - 15%)

    .circle3
      right: 0

    .outer-circle
      display: flex
      align-items: center
      justify-content: center
      margin: auto
      width: 32px
      height: 32px
      border-radius: 16px
      box-sizing: border-box

      .inner-circle
        width: 12px
        height: 12px
        border-radius: 16px
        box-sizing: border-box

  .podcaster-info-detail-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 24px
    padding: 0
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    border-style: none
    border-width: 2px
    border-color: #4b4b4b
    border-radius: 0

  .podcaster-photo-detail
    width: 102px
    height: 102px
    border-radius: 34px

  .podcaster-name
    margin-top: 0
    margin-bottom: 16px
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 28px
    line-height: 140%

  .social-link-wrap
    display: flex

    .page-link:last-child
      a
        margin-right: 0

    @media (max-width: 479px)
      justify-content: center

  .social-link-box
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 48px
    height: 48px
    margin-right: 8px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    border-radius: 12px
    background-color: #4b4b4b
    -webkit-transition: all 200ms ease
    transition: all 200ms ease

    &:hover
      background-color: #6f6f6f

  .social-logo
    width: 24px
    height: 24px

  .progress-prompt-text
    text-align: center
    width: 100%
    margin-bottom: 0
    padding: 12px 24px
    border-radius: 12px
    background-color: #4b4b4b
    font-family: Roboto, sans-serif
    color: #fff
    line-height: 140%

  .form-block
    width: 100%
    margin-bottom: 0

  .comment-divider
    height: 1px
    margin-top: 24px
    margin-bottom: 24px
    margin-left: 64px
    background-color: #4b4b4b

  .bottom-bar
    position: fixed
    left: 0
    top: auto
    right: 0
    bottom: 0
    display: none
    padding: 12px 16px
    border-style: solid none none
    border-width: 1px
    border-color: #6f6f6f
    background-color: #4b4b4b

  .nav-grid
    display: -ms-grid
    display: grid
    width: 100%
    margin: 0
    padding: 0
    -webkit-box-pack: start
    -webkit-justify-content: flex-start
    -ms-flex-pack: start
    justify-content: flex-start
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    grid-auto-flow: column
    grid-auto-columns: max-content
    grid-column-gap: 20px
    grid-row-gap: 20px
    -ms-grid-rows: auto
    grid-template-rows: auto

  .nav-logo-link
    display: block
    height: 60px
    margin-right: 0
    margin-left: 0
    padding: 0

  .nav-logo
    width: auto
    height: 100%
    -o-object-fit: contain
    object-fit: contain
    -o-object-position: 0 50%
    object-position: 0 50%

  .nav-link
    display: block
    margin-right: 0
    margin-left: 0
    padding: 10px 0
    color: #444
    text-decoration: none

    &:hover
      text-decoration: underline

  .nav-menu
    padding: 12px 16px
    border-radius: 12px
    -webkit-transition: all 200ms ease
    transition: all 200ms ease
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 14px
    line-height: 140%
    font-weight: 700

    &:hover
      background-color: #111
      color: #ffdd15

  .hoost-logo
    -webkit-box-flex: 1
    -webkit-flex: 1
    -ms-flex: 1
    flex: 1

  .nav-container
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    max-width: 1200px
    padding-right: 16px
    padding-left: 16px
    -webkit-justify-content: space-around
    -ms-flex-pack: distribute
    justify-content: space-around
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    -webkit-box-flex: 1
    -webkit-flex: 1
    -ms-flex: 1
    flex: 1

  .nav-sub-menu
    margin-right: 24px
    @media (max-width: 779px)
      margin-right: 18px
    padding-top: 16px
    color: #aaa
    padding-bottom: 18px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-bottom: 3px solid #2d2d2d
    -webkit-transition: all 200ms ease
    transition: all 200ms ease
    font-family: Roboto, sans-serif
    line-height: 140%
    font-weight: 700
    text-decoration: none

    &:hover
      border-bottom-width: 4px
      border-bottom-color: #ffdd15
      color: #fff

    &.active
      border-style: none none solid
      border-width: 1px 1px 4px
      border-color: #000 #000 #ffdd15
      color: #fff

  .sub-menu-wrap
    overflow-x: scroll !important
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    height: 56px
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    -webkit-align-self: center
    align-self: center
    -webkit-box-flex: 1
    -webkit-flex: 1
    -ms-flex: 1
    flex: 1

    &::-webkit-scrollbar
      display: none

  .sub-nav-button-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    height: 56px
    -webkit-align-self: center
    align-self: center

  .h3
    margin-top: 0
    margin-bottom: 16px
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 28px
    line-height: 125%

  .h2
    margin-top: 0
    margin-bottom: 16px
    font-family: Roboto, sans-serif
    color: #fff
    line-height: 125%

  .body-text
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #ccc
    font-size: 16px
    line-height: 160%
    font-weight: 400

    &.mb8
      margin-bottom: 8px

  @media screen and (max-width: 991px)
    .container-1200._2-col
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .panel-base.swap-position
      margin-bottom: 24px
      -webkit-box-ordinal-group: 0
      -ms-flex-order: -1
      order: -1

    .show-content-wrap
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .show-cover
      width: 100%
      height: auto
      margin-bottom: 24px

    .image
      width: 100%

    .section-body-text
      font-size: 16px

    .menu-button
      padding: 6px
      border-radius: 8px
      background-color: #fff

    .icon
      color: #111

  @media screen and (max-width: 767px)
    .container-1200._2-col
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .panel-base
      padding: 32px
      -webkit-box-ordinal-group: 1
      -ms-flex-order: 0
      order: 0
      border-radius: 12px

      &.flex-grow
        margin-bottom: 0
        -webkit-box-ordinal-group: 2
        -ms-flex-order: 1
        order: 1

      &.swap-position
        margin-bottom: 16px

    .h1
      font-size: 32px

    .show-content-wrap
      margin-bottom: 16px

    .podcaster-info-wrap
      margin-bottom: 16px
      padding: 12px

    .button-wrap
      display: inline-block

      &.mb24
        margin-bottom: 16px
        -webkit-box-orient: vertical
        -webkit-box-direction: normal
        -webkit-flex-direction: column
        -ms-flex-direction: column
        flex-direction: column

      &.mb16
        -webkit-box-orient: vertical
        -webkit-box-direction: normal
        -webkit-flex-direction: column
        -ms-flex-direction: column
        flex-direction: column

    .button
      &.button-secondary
        -webkit-box-flex: 0
        -webkit-flex: 0 auto
        -ms-flex: 0 auto
        flex: 0 auto

        &.mr12
          -webkit-box-pack: center
          -webkit-justify-content: center
          -ms-flex-pack: center
          justify-content: center
          -webkit-box-flex: 1
          -webkit-flex: 1
          -ms-flex: 1
          flex: 1

      &.full-width.mr12
        width: 100%
        margin-right: 0
        margin-bottom: 12px

      &.flex-width
        flex: 1 auto

    .link-block.button-scondary
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

    .show-price-wrap
      margin-bottom: 16px
      padding: 12px

    .div
      margin-right: 16px
      margin-left: 16px

    .discount-tag
      padding: 4px 6px
      border-radius: 4px
      font-size: 12px
      line-height: 140%

    .section-basic
      margin-bottom: 16px

      &.mt108
        margin-top: 130px
    .section-body-text
      font-size: 16px

    .podcaster-info-detail-wrap
      margin-bottom: 16px
      padding: 12px

    .bottom-bar
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      z-index: 99
      background-color: #2d2d2d

    .sticky-nav
      position: fixed
      top: 82px

    .nav-grid
      text-align: center

    .hoost-logo
      padding-left: 0

    .sub-nav-button-wrap
      display: none

    .menu-button
      overflow: hidden
      padding: 0
      border-radius: 8px
      background-color: #111

      &.w--open
        overflow: hidden
        border-radius: 8px
        background-color: #ececec

    .icon
      overflow: hidden
      padding: 6px
      border-radius: 8px
      background-color: #ececec
      color: #111

  @media screen and (max-width: 479px)
    .container-1200._2-col
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .panel-base
      margin-right: 8px
      margin-left: 8px
      padding: 24px

      &.flex-grow
        margin-bottom: 0

    .show-title-wrap
      margin-bottom: 16px

    .h1
      font-size: 24px

      &.mb16
        margin-bottom: 8px

    .show-tag-wrap
      -webkit-flex-wrap: wrap
      -ms-flex-wrap: wrap
      flex-wrap: wrap

    .tag
      margin-top: 4px
      margin-right: 4px
      margin-bottom: 4px

    .show-cover
      border-radius: 12px

    .show-info
      width: 100%

    .podcaster-photo.large-ring
      margin-right: 0
      margin-bottom: 16px

    .image
      overflow: hidden
      border-radius: 12px

    .button.comment-button
      @media (max-width: 479px)
        margin-top: 12px
        width: 100%
      @media (min-width: 479px)
        flex: 2

    .show-price
      font-size: 24px

    .price-wrap
      margin-bottom: 12px
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .section-basic
      margin-bottom: 16px

      &.mt108
        margin-top: 130px

    .funding-amount-wrap
      flex-direction: row
      align-items: flex-end

    .funding-percentage-wrap
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .funding-progress
      position: relative
      width: 100%
      min-width: 240px
      height: 120px

    .order-title
      margin-bottom: 0
      align-self: flex-end
      font-size: 12px

      &.mr0
        margin-bottom: 2px
        -webkit-align-self: flex-end
        align-self: flex-end

    .progress-achieve-number
      font-size: 24px

    .achieve-title
      margin-right: 0
      margin-bottom: 4px
      font-size: 12px

    .section-title
      font-size: 28px

    .order-num-wrap
      -webkit-box-align: end
      -webkit-align-items: flex-end
      -ms-flex-align: end
      align-items: flex-end
      -webkit-align-self: flex-start
      align-self: flex-start

    .podcaster-info-detail-wrap
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .podcaster-name
      margin-bottom: 12px
      text-align: center

    .comment-divider
      margin-left: 0

    .bottom-bar
      background-color: #2d2d2d

    .sticky-nav
      padding: 0

    .nav-grid
      grid-auto-flow: row
      grid-column-gap: 0
      grid-row-gap: 0
      -ms-grid-columns: 1fr
      grid-template-columns: 1fr
      -ms-grid-rows: 100px
      grid-template-rows: 100px

    .nav-logo-link
      border-top: 1px solid #c4c4c4

    .nav-link
      padding-top: 10px
      padding-bottom: 10px
      border-top: 1px solid #c4c4c4

    .hoost-logo
      padding-left: 0

    .nav-container
      -webkit-box-pack: start
      -webkit-justify-content: flex-start
      -ms-flex-pack: start
      justify-content: flex-start

    .nav-sub-menu
      -webkit-box-flex: 0
      -webkit-flex: 0 0 auto
      -ms-flex: 0 0 auto
      flex: 0 0 auto

    .sub-menu-wrap
      overflow: hidden
      -webkit-flex-wrap: nowrap
      -ms-flex-wrap: nowrap
      flex-wrap: nowrap
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

    .avatar-image
      width: 100%
      height: 100%

  &.inactive
    position: fixed
    bottom: -160

  .inner
    @media (max-width: $md)
      width: 80%
    @media (min-width: $md)
      width: 60%
