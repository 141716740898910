@import "Colors"
@import "Width"

.order-list
    background-color: $background-black
    min-height: 100vh
    padding-top: 120px
    color: white

    @media (max-width: $md)
        padding-right: 0
        padding-left: 0
    @media (min-width: $md) and (max-width: $xl)
        padding-left: 60px
        padding-right: 60px
    @media (min-width: $xl)
        padding-left: 20%
        padding-right: 20%

    h1
        color: white
        margin-bottom: 28px
        padding: 0 0 36px
        border-bottom: 1px solid #474747

        @media (max-width: $md)
            padding-left: 16px

    .table-head
        padding-bottom: 28px

        p
            font-size: 18px
            margin: 0

    .order-list-item
        padding: 24px 0
        border-top: 1px solid #474747
        color: white

        .topic-and-podcast-name
            margin-bottom: 8px

        .topic-name
            margin: 0

            @media (max-width: $md)
                font-size: 14px
            @media (min-width: $md)
                font-size: 18px

        .podcast-name
            color: #b0b0b0
            margin-top: 8px

            @media (max-width: $md)
                font-size: 12px
            @media (min-width: $md)
                font-size: 16px

        img
            width: 100%
            border-radius: 10px

        .label
            color: white
            font-weight: bolder
            margin-right: 16px

            @media (max-width: $md)
                font-size: 12px

        p
            color: #b0b0b0
            display: inline

            @media (max-width: $md)
                font-size: 12px
