@import "Colors.sass"
@import "Width.sass"

.topBar

  .nav-logo-link
    display: block
    margin-right: 0
    margin-left: 0
    padding: 0

  .nav-logo
    width: auto
    height: 100%
    -o-object-fit: contain
    object-fit: contain
    -o-object-position: 0 50%
    object-position: 0 50%

  .nav-link
    display: block
    margin-right: 0
    margin-left: 0
    padding: 10px 0
    color: #444
    text-decoration: none

    &:hover
      text-decoration: underline

  .navbar
    position: fixed
    top: 0
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 100%
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    border-bottom: 1px solid #000
    background-color: #1B1B1B
    -o-object-fit: fill
    object-fit: fill
    justify-content: space-between

  .nav-menu
    padding: 12px 16px
    border-radius: 12px
    -webkit-transition: all 200ms ease
    transition: all 200ms ease
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 14px
    line-height: 140%
    font-weight: 700

    &:hover
      background-color: #111
      color: #ffdd15

  .hoost-logo
    -webkit-box-flex: 1
    -webkit-flex: 1
    -ms-flex: 1
    flex: 1

  .nav-container
    display: flex
    max-width: 1200px
    padding-right: 16px
    padding-left: 16px
    align-items: center
    width: 100%
    justify-content: space-between

  .nav-sub-menu
    margin-right: 24px
    padding-top: 18px
    padding-bottom: 16px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-bottom: 3px solid #2d2d2d
    -webkit-transition: all 200ms ease
    transition: all 200ms ease
    font-family: Roboto, sans-serif
    line-height: 140%
    font-weight: 700
    text-decoration: none

    &:hover
      border-bottom-width: 4px
      border-bottom-color: #ffdd15
      color: #fff

    &.active
      border-style: none none solid
      border-width: 1px 1px 4px
      border-color: #000 #000 #ffdd15
      color: #fff

  .h3
    margin-top: 0
    margin-bottom: 16px
    font-family: Roboto, sans-serif
    color: #fff
    font-size: 28px
    line-height: 125%

  .h2
    margin-top: 0
    margin-bottom: 16px
    font-family: Roboto, sans-serif
    color: #fff
    line-height: 125%

  .body-text
    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #ccc
    font-size: 16px
    line-height: 160%
    font-weight: 400

    &.mb8
      margin-bottom: 8px

  @media screen and (max-width: 991px)
    .container-1200._2-col
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .panel-base.swap-position
      margin-bottom: 24px
      -webkit-box-ordinal-group: 0
      -ms-flex-order: -1
      order: -1

    .show-content-wrap
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .show-cover
      width: 100%
      height: 100%
      margin-bottom: 24px

    .image
      width: 100%
      height: 100%

    .section-body-text
      font-size: 16px

    .menu-button
      padding: 6px
      border-radius: 8px
      background-color: #fff

    .icon
      color: #111

    .nav-menu-2
      padding: 12px 16px

  @media screen and (max-width: 767px)
    .container-1200._2-col
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .button-wrap
      &.mb24
        margin-bottom: 16px
        -webkit-box-orient: vertical
        -webkit-box-direction: normal
        -webkit-flex-direction: column
        -ms-flex-direction: column
        flex-direction: column

      &.mb16
        -webkit-box-orient: vertical
        -webkit-box-direction: normal
        -webkit-flex-direction: column
        -ms-flex-direction: column
        flex-direction: column

    .hoost-logo
      padding-left: 0

    .sub-nav-button-wrap
      display: none

    .menu-button
      overflow: hidden
      padding: 0
      border-radius: 8px
      background-color: #111

      &.w--open
        overflow: hidden
        border-radius: 8px
        background-color: #ececec

    .icon
      overflow: hidden
      padding: 6px
      border-radius: 8px
      background-color: #ececec
      color: #111

    .nav-menu-2
      padding: 12px 16px

  @media screen and (max-width: 479px)
    .container-1200._2-col
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .panel-base
      margin-right: 8px
      margin-left: 8px
      padding: 24px

      &.flex-grow
        margin-bottom: 0

    .show-title-wrap
      margin-bottom: 16px

    .h1
      font-size: 24px

      &.mb16
        margin-bottom: 8px

    .show-tag-wrap
      -webkit-flex-wrap: wrap
      -ms-flex-wrap: wrap
      flex-wrap: wrap

    .tag
      margin-top: 4px
      margin-right: 4px
      margin-bottom: 4px

    .show-cover
      border-radius: 12px

    .show-info
      width: 100%

    .podcaster-photo.large-ring
      margin-right: 0
      margin-bottom: 16px

    .image
      border-radius: 12px

    .button.comment-button
      width: 100%

    .show-price
      font-size: 24px

    .price-wrap
      margin-bottom: 12px
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .section-basic
      margin-bottom: 16px

    .funding-amount-wrap
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column
      -webkit-box-align: start
      -webkit-align-items: flex-start
      -ms-flex-align: start
      align-items: flex-start

    .funding-percentage-wrap
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .order-title
      margin-right: 0
      margin-bottom: 4px
      -webkit-align-self: flex-start
      align-self: flex-start
      font-size: 12px

      &.mr0
        margin-bottom: 2px
        -webkit-align-self: flex-end
        align-self: flex-end

    .progress-goal-number
      margin-right: 4px

    .progress-achieve-number
      font-size: 24px

    .achieve-title
      margin-right: 0
      margin-bottom: 4px
      font-size: 12px

    .section-title
      font-size: 20px

    .order-num-wrap
      -webkit-box-align: end
      -webkit-align-items: flex-end
      -ms-flex-align: end
      align-items: flex-end
      -webkit-align-self: flex-start
      align-self: flex-start

    .podcaster-info-detail-wrap
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .podcaster-name
      margin-bottom: 12px
      text-align: center

    .bottom-bar
      background-color: #2d2d2d

    .sticky-nav
      padding: 0

    .nav-logo-link
      border-top: 1px solid #c4c4c4

    .nav-link
      padding-top: 10px
      padding-bottom: 10px
      border-top: 1px solid #c4c4c4

    .hoost-logo
      padding-left: 0

    .nav-container
      -webkit-box-pack: start
      -webkit-justify-content: flex-start
      -ms-flex-pack: start
      justify-content: flex-start

    .nav-sub-menu
      -webkit-box-flex: 0
      -webkit-flex: 0 0 auto
      -ms-flex: 0 0 auto
      flex: 0 0 auto

    .sub-menu-wrap
      overflow: hidden
      -webkit-flex-wrap: nowrap
      -ms-flex-wrap: nowrap
      flex-wrap: nowrap
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

  .user-menu-inactive, .dismiss-trigger-inactive
    display: none

  .user-menu-active
    z-index: 5001
    transition: opacity 0.3s ease-in
    position: absolute
    right: 0
    top: 36px
    padding: 8px
    width: 360px
    border-radius: 10px
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
    background-color: #2d2f30
    display: inline-block
    color: white

    p
      padding: 12px 8px
      margin: 0
      line-height: 16px
      font-size: 16px

      &:hover
        background-color: initial

    .user-info
      cursor: initial
      padding: 16px 0
      border-bottom: solid 1px #474747
      display: flex

    .menu-item
      border-radius: 10px

      &:hover
        background-color: #404244

  .dismiss-trigger-active
    z-index: 5000
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100vh
