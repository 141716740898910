.render-article

    margin-bottom: 0
    font-family: Roboto, sans-serif
    color: #ccc
    font-size: 16px
    line-height: 160%

    img
        margin-top: 16px
        padding-bottom: 16px
        width: 100% !important

    h1
        margin-top: 24px
        margin-bottom: 0
        border-style: none
        border-width: 1px
        border-color: #fff
        font-family: Roboto, sans-serif
        color: #fff
        font-size: 40px
        line-height: 125%

    h2
        margin-top: 24px
        margin-bottom: 0px
        font-family: Roboto, sans-serif
        color: #fff
        font-size: 28px
        line-height: 125%

    h3
        margin-top: 24px
        padding-bottom: 12px
        font-family: Roboto, sans-serif
        color: #fff
        font-size: 24px
        line-height: 125%

    h4
        margin-top: 24px
        margin-bottom: 0px
        font-family: Roboto, sans-serif
        color: #fff
        font-size: 20px
        line-height: 140%

        &.black-text
            color: #111

            &.mb8
                margin-bottom: 8px

    h5
        margin-top: 24px
        margin-bottom: 0px
        font-family: Roboto, sans-serif
        color: #fff
        font-size: 16px
        line-height: 140%

    h6
        margin-top: 24px
        margin-bottom: 0px
        font-family: Roboto, sans-serif
        color: #fff
        font-size: 14px
        line-height: 140%

    p
        margin-top: 12px
        padding-bottom: 12px
        font-size: 16px

    strong
        color: #fff
    
    a
        color: #ffdd15

.render-article > :first-child
    margin-top: 0px
