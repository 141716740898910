.sku-card
  margin: 12px 5px
  padding: 24px
  border-radius: 8px
  transition: all 0.15s ease-in-out
  border-style: solid
  border-width: 1px
  border-color: #4B4B4B
  background-color: #2d2d2d
  -webkit-mask-image: -webkit-radial-gradient(white, black)

  @media (min-width: 991px)

    flex: 0 0 calc(33.333% - 7px)

    &:nth-child(3n+1)
      margin: 12px 5px 12px 0

    &:nth-child(3n+3)
      margin: 12px 0 12px 5px

  @media (min-width: 479px) and (max-width: 991px)
    flex: 0 0 calc(50% - 6px)

    &:nth-child(2n+1)
      margin: 12px 5px 12px 0

    &:nth-child(2n+2)
      margin: 12px 0 12px 5px

  @media screen and (max-width: 479px)
    flex: 0 0 calc(100%)
    margin: 12px 0

    &:first-child
      margin-top: 0

  .sku-name
    height: auto
    display: flex
    align-items: center
    max-height: 56px
    margin-bottom: 4px
    color: #fff
    font-size: 20px
    line-height: 140%
    font-weight: 700
    text-align: left
    text-transform: none

  .countdown-text
    margin-bottom: 16px !important

  .sku-price
    display: flex
    justify-content: space-between
    margin-top: 12px

  .sku-bought-amount
    color: #ffffff

  .sku-description
    margin-bottom: 16px
    color: #b0b0b0
    font-size: 16px
    line-height: 140%
    overflow-y: scroll
    font-weight: 400
    text-align: left
    text-transform: none
    transition: opacity .15s ease-in-out

    /* width */



    &::-webkit-scrollbar
      width: 8px

    /* Track */



    &::-webkit-scrollbar-track
      opacity: 0


    /* Handle */



    &::-webkit-scrollbar-thumb
      background: #333
      border-radius: 4px

    &:hover
      &::-webkit-scrollbar-thumb
        background: #444

    /* Handle on hover */



    &::-webkit-scrollbar-thumb:hover
      background: #555

  &:hover
    border-color: #6f6f6f
