.comment-input-component

    .my-comment-wrap
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        margin-top: 16px
        -webkit-box-align: start
        -webkit-align-items: flex-start
        -ms-flex-align: start
        align-items: flex-start

    .my-comment-avatar
        overflow: hidden
        width: 40px
        height: 40px
        margin-right: 24px
        border-radius: 100px

    .my-comment-form-wrapper
        width: 100%

    .my-comment-box
        flex: 8
        resize: none
        height: auto
        margin-right: 16px
        margin-bottom: 0
        padding: 12px
        border-style: solid
        border-width: 2px
        border-color: #4b4b4b
        border-radius: 12px
        background-color: #4b4b4b
        -webkit-transition: all 200ms ease
        transition: all 200ms ease
        font-family: Roboto, sans-serif
        color: #6f6f6f
        font-size: 16px

        &:hover
            border-color: #6f6f6f

        &:focus
            border-style: solid
            border-width: 2px
            border-color: #ffdd15
            color: #fff

        &::-webkit-input-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder, &::placeholder
            color: #ccc

    .my-comment-form
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        width: 100%
        -webkit-box-align: stretch
        -webkit-align-items: stretch
        -ms-flex-align: stretch
        align-items: stretch
        color: #909090

    @media screen and ( max-width: 479px )

        .my-comment-wrap
            flex-wrap: wrap
            align-items: flex-start
            align-content: flex-start

        .my-comment-avatar
            display: none
            margin-bottom: 12px
            -webkit-flex: 0 0 auto
            -ms-flex: 0 0 auto
            flex: 0 0 auto

        .my-comment-form
            -webkit-box-orient: vertical
            -webkit-box-direction: normal
            -webkit-flex-direction: column
            -ms-flex-direction: column
            flex-direction: column
