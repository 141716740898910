.sticky-nav
    position: -webkit-sticky
    position: sticky
    top: 82px
    z-index: 1
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    height: 56px
    width: 100%
    padding: 20px
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    background-color: rgb(33, 33, 33)
    box-shadow: 0 8px 0 0 rgba(0, 0, 0, 0.2)

    .nav-container
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        max-width: 1200px
        padding-right: 16px
        padding-left: 16px
        -webkit-justify-content: space-around
        -ms-flex-pack: distribute
        justify-content: space-around
        -webkit-box-align: center
        -webkit-align-items: center
        -ms-flex-align: center
        align-items: center
        -webkit-box-flex: 1
        -webkit-flex: 1
        -ms-flex: 1
        flex: 1

    .sub-menu-wrap
      overflow-y: hidden
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      height: 56px
      -webkit-box-align: center
      -webkit-align-items: center
      -ms-flex-align: center
      align-items: center
      -webkit-align-self: center
      align-self: center
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

    .sub-nav-button-wrap
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      height: 56px
      -webkit-align-self: center
      align-self: center

      .button
        padding: 15px 24px
        -webkit-align-self: flex-end
        align-self: flex-end
        border-radius: 12px
        background-color: #ffdd15
        -webkit-transition: all 200ms ease
        transition: all 200ms ease
        font-family: Roboto, sans-serif
        color: #111
        font-size: 16px
        line-height: 112.5%
        font-weight: 700
        text-align: center

        &:hover
          background-color: rgba(255, 221, 21, 0.8)

        &.button-secondary
          border-style: solid
          border-width: 1px
          border-color: #fff
          background-color: transparent
          color: #fff

        &.mr12
          display: -webkit-box
          display: -webkit-flex
          display: -ms-flexbox
          display: flex

        &.full-width
          -webkit-box-flex: 1
          -webkit-flex: 1
          -ms-flex: 1
          flex: 1

          &.mr12
            -webkit-box-pack: center
            -webkit-justify-content: center
            -ms-flex-pack: center
            justify-content: center
            font-family: Roboto, sans-serif

        &.small
          padding: 8px 12px
          -webkit-align-self: center
          -ms-grid-row-align: center
          align-self: center
          border-radius: 8px
          font-size: 14px

          &.mr8
            margin-right: 8px

      .button-text
        margin-right: 4px
        margin-bottom: 0
        color: #fff
        font-size: 16px
        line-height: 112.5%
        font-weight: 700

      .button
        &.button-secondary
          -webkit-box-flex: 0
          -webkit-flex: 0 auto
          -ms-flex: 0 auto
          flex: 0 auto

          &.mr12
            -webkit-box-pack: center
            -webkit-justify-content: center
            -ms-flex-pack: center
            justify-content: center
            -webkit-box-flex: 1
            -webkit-flex: 1
            -ms-flex: 1
            flex: 1

        &.full-width.mr12
          width: 100%
          margin-right: 0
          margin-bottom: 12px
