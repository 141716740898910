@import "Colors"

.not-found-page
    .banner-bg
        background-size: cover
        background-position-x: center
        background-repeat: no-repeat
        text-align: center
        display: flex
        justify-content: center
        align-items: center
        margin-top: 10vh
        height: 70vh

        .bg-overlay
            z-index: 1
            position: absolute
            top: 0
            width: 100%
            height: 80vh
            background-color: rgba(0, 0, 0, 0.7)


        .banner-text
            z-index: 2

            h1
                margin: 0px 36px
                color: white


            h5
                color: white


            strong
                color: $theme-yellow


            button
                margin-top: 64px
                background-color: white
                border: none
                border-radius: 36px
                padding: 18px 48px
                font-weight: 800
                cursor: pointer




