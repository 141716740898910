.episode-player-component

    .h4
        margin-top: 0
        margin-bottom: 16px
        font-family: Roboto, sans-serif
        color: #fff
        font-size: 24px
        line-height: 140%

        &.black-text
            color: #111

            &.mb8
                margin-bottom: 8px

    .trial-player
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        margin-bottom: 24px
        padding: 40px
        border-style: solid
        border-width: 2px
        border-color: #4b4b4b
        border-radius: 20px
        background-color: #faf8f4
        box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.4)

        @media screen and (max-width: 991px)
            padding: 24px

    .player-content
        display: flex
        -webkit-align-self: center
        align-self: center
        -webkit-box-flex: 1
        -webkit-flex: 1
        -ms-flex: 1
        flex: 1

    .player-control
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        -webkit-box-orient: vertical
        -webkit-box-direction: normal
        -webkit-flex-direction: column
        -ms-flex-direction: column
        flex-direction: column
        -webkit-box-align: end
        -webkit-align-items: flex-end
        -ms-flex-align: end
        align-items: flex-end

    .episode-cover
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        width: 120px
        height: 120px
        margin-right: 24px
        -webkit-box-pack: center
        -webkit-justify-content: center
        -ms-flex-pack: center
        justify-content: center
        -webkit-box-align: center
        -webkit-align-items: center
        -ms-flex-align: center
        align-items: center
        border: 2px solid #000
        border-radius: 12px

    .episode-image
        overflow: hidden
        width: 108px
        height: 108px
        border-radius: 8px

    .episode-info
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        margin-right: 40px
        -webkit-box-orient: vertical
        -webkit-box-direction: normal
        -webkit-flex-direction: column
        -ms-flex-direction: column
        flex-direction: column
        -webkit-box-align: start
        -webkit-align-items: flex-start
        -ms-flex-align: start
        align-items: flex-start
        -webkit-box-flex: 1
        -webkit-flex: 1
        -ms-flex: 1
        flex: 1

    .player-slider-wrap
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        width: 100%
        -webkit-box-align: center
        -webkit-align-items: center
        -ms-flex-align: center
        align-items: center

    .player-slider-main
        width: 0
        height: 8px
        margin-right: 12px
        margin-left: 12px
        -webkit-box-flex: 1
        -webkit-flex: 1
        -ms-flex: 1
        flex: 1
        border-radius: 100px
        background-color: #ccc

    .play-time
        margin-bottom: 0
        -webkit-align-self: center
        -ms-grid-row-align: center
        align-self: center
        font-family: Roboto, sans-serif
        font-size: 18px
        line-height: 140%

    .paragraph-2
        -webkit-align-self: center
        -ms-grid-row-align: center
        align-self: center

    .link
        font-family: Roboto, sans-serif
        color: #111
        font-size: 16px
        line-height: 140%
        font-weight: 700
        text-decoration: none

    .paragraph-3
        margin-bottom: 0
        font-family: Roboto, sans-serif
        color: #111
        font-size: 16px
        line-height: 140%
        font-weight: 700
        text-decoration: none

    .play-speed-box
        margin-bottom: 16px
        padding: 9px 8px
        border-style: solid
        border-width: 1px
        border-color: #ececec
        border-radius: 8px
        background-color: #ececec
        -webkit-transition: all 200ms ease
        transition: all 200ms ease
        text-decoration: none

        &:hover
            border-style: solid
            border-width: 1px
            border-color: #909090
            background-color: #ececec

    .player-control-box
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        -webkit-box-align: center
        -webkit-align-items: center
        -ms-flex-align: center
        align-items: center

    .icon-box-40
        width: 40px
        height: 40px

    .play-button-box
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        width: 64px
        height: 64px
        margin-right: 12px
        margin-left: 12px
        -webkit-box-pack: center
        -webkit-justify-content: center
        -ms-flex-pack: center
        justify-content: center
        -webkit-box-align: center
        -webkit-align-items: center
        -ms-flex-align: center
        align-items: center
        border-radius: 100px
        background-color: #111
        box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.12)

    .play-icon
        width: 32px
        height: 32px

.play-btn
    height: 48px
    width: 48px

    @media screen and (max-width: 767px)

        .h4
            font-size: 20px

        .trial-player
            -webkit-box-orient: vertical
            -webkit-box-direction: normal
            -webkit-flex-direction: column
            -ms-flex-direction: column
            flex-direction: column

        .player-content
            display: inline-block
            margin-bottom: 16px
            -webkit-box-align: center
            -webkit-align-items: center
            -ms-flex-align: center
            align-items: center
            -webkit-align-self: stretch
            -ms-grid-row-align: stretch
            align-self: stretch

        .player-control
            width: 100%
            -webkit-box-orient: horizontal
            -webkit-box-direction: normal
            -webkit-flex-direction: row
            -ms-flex-direction: row
            flex-direction: row
            -webkit-box-pack: justify
            -webkit-justify-content: space-between
            -ms-flex-pack: justify
            justify-content: space-between
            -webkit-box-align: center
            -webkit-align-items: center
            -ms-flex-align: center
            align-items: center
            -webkit-align-self: center
            -ms-grid-row-align: center
            align-self: center

        .episode-cover
            width: 108px
            height: 108px
            margin-right: 16px
            -webkit-align-self: flex-start
            align-self: flex-start

        .episode-image
            width: 96px
            height: 96px

        .episode-info
            margin-right: 0

        .play-speed-box
            margin-right: 16px
            margin-bottom: 0
            -webkit-align-self: center
            -ms-grid-row-align: center
            align-self: center
            -webkit-box-flex: 0
            -webkit-flex: 0 auto
            -ms-flex: 0 auto
            flex: 0 auto

        .player-control-box
            -webkit-align-self: center
            -ms-grid-row-align: center
            align-self: center
            -webkit-box-flex: 0
            -webkit-flex: 0 0 auto
            -ms-flex: 0 0 auto
            flex: 0 0 auto
            
        .play-icon
            width: 24px
            height: 24px

    @media screen and ( max-width: 479px )

        .player-content
            display: inline-block
            -webkit-box-orient: vertical
            -webkit-box-direction: normal
            -webkit-flex-direction: column
            -ms-flex-direction: column
            flex-direction: column

        .episode-cover
            margin-right: 0
            margin-bottom: 16px
            -webkit-align-self: flex-start
            align-self: flex-start

        .play-button-box
            width: 48px
            height: 48px

        .play-icon
            width: 24px
            height: 24px


.player-float
    width: 100%
    .player-rate
        position: absolute
        top: -100px
        right: 0px
        display: flex
        flex-direction: column
        align-items: center
        background: #484848
        border-radius: 12px
        padding: 10px 20px
        .title
            font-weight: bold
            font-size: 16px
            margin-bottom: 12px
        .rate
            display: flex
            gap: 8px
            font-size: 12px
            a
                padding: 9px 8px
            .rate-active
                border: 1px solid #ECECEC
                border-radius: 8px
    .player-horizontal, .player-vertical
        width: calc(70% - 24px)
        min-width: 250px
        position: fixed
        bottom: 12px
        left: 30%
        background-color: #484848
        box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.5)
        border-radius: 12px
        margin: 0px 12px
    .player-horizontal
        display: grid
        grid-template-columns: auto max-content
        grid-column-gap: 32px
        padding: 16px 24px
    .player-vertical
        display: block
        // height: 529px
        text-align: center
        padding: 16px 16px 29px 16px
        // z-index: 1000
        .lession-img
            padding: 24px
            img
                height: 240px
                border-radius: 8px
    .control-box
        display: flex
        gap: 16px
        align-items: center
        justify-content: center
        a
            cursor: pointer
    .play-rate
        border: 1px solid #ECECEC
        border-radius: 8px
        font-size: 12px
        padding: 9px 8px
    .play-title
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
    .lesson-title
        display: grid
        grid-template-columns: max-content auto
        grid-column-gap: 12px
        margin-top: 2px

.rhap_container
    margin: 0 !important
.rhap_progress-container
    margin: 0 !important
.rhap_progress-indicator
    width: 16px !important
    height: 16px !important
    top: -6px !important
    margin-left: 0 !important

@media screen and ( max-width: 768px )
    .player-float
        .player-horizontal, .player-vertical
            width: calc(100% - 24px)
            left: 0px
        .player-horizontal
            padding: 12px
        .player-info
            display: flex
            flex-direction: column-reverse
        .lesson-title
            grid-template-columns: auto auto
    .player-mobile
        display: none !important
    .play-btn
        height: 36px
        width: 36px