.comment-card-component
    transition: all 0.3s ease-in-out
    -webkit-animation: fadein 0.3s
    -moz-animation: fadein 0.3s
    -o-animation: fadein 0.3s
    animation: fadein 0.3s

    .reply-wrap
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        margin-bottom: 16px

        &.ml64
            margin-left: 64px

    .replier-info-box
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        margin-bottom: 4px
        -webkit-box-align: center
        -webkit-align-items: center
        -ms-flex-align: center
        align-items: center

    .replier-name
        margin-right: 8px
        margin-bottom: 0
        font-family: Roboto, sans-serif
        color: #fff
        font-size: 18px
        line-height: 140%
        font-weight: 700

    .replier-avatar
        min-width: 40px
        overflow: hidden
        width: 40px
        height: 40px
        margin-right: 24px
        border-radius: 100px

    .reply-timecode
        margin-bottom: 0
        font-family: Roboto, sans-serif
        color: #6f6f6f
        font-size: 16px
        line-height: 140%

    .reply-button-wrap
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        -webkit-box-align: center
        -webkit-align-items: center
        -ms-flex-align: center
        align-items: center

    ._2nd-reply-wrap
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        margin-left: 64px
        -webkit-box-align: start
        -webkit-align-items: flex-start
        -ms-flex-align: start
        align-items: flex-start

    .my-comment-avatar
        overflow: hidden
        width: 40px
        height: 40px
        margin-right: 24px
        border-radius: 100px

    .my-comment-box
        flex: 8
        resize: none
        height: auto
        margin-right: 16px
        margin-bottom: 0
        padding: 12px
        border-style: solid
        border-width: 2px
        border-color: #4b4b4b
        border-radius: 12px
        background-color: #4b4b4b
        -webkit-transition: all 200ms ease
        transition: all 200ms ease
        font-family: Roboto, sans-serif
        color: #6f6f6f
        font-size: 16px

        &:hover
            border-color: #6f6f6f

        &:focus
            border-style: solid
            border-width: 2px
            border-color: #ffdd15
            color: #fff

        &::-webkit-input-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder, &::placeholder
            color: #ccc

    .my-comment-form
        display: -webkit-box
        display: -webkit-flex
        display: -ms-flexbox
        display: flex
        width: 100%
        -webkit-box-align: stretch
        -webkit-align-items: stretch
        -ms-flex-align: stretch
        align-items: stretch
        color: #909090
