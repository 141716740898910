@import "Colors"
@import "./TopicBuyPage"

html, body
  font-family: Noto Sans TC, "微軟正黑體", sans-serif !important
  margin: 0
  width: 100%
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  background-color: #111 !important
  scroll-behavior: smooth

button
  font-family: Noto Sans TC, "微軟正黑體", sans-serif !important

a
  color: #15c7ff

  &:hover
    text-decoration: underline

.line-button
  position: fixed
  z-index: 5000

  @media (max-width: 729px)
    bottom: 16px
    right: 16px

  @media (min-width: 729px)
    bottom: 32px
    right: 32px

.line-button-inner
  @media (max-width: 729px)
    width: 60px

  @media (min-width: 729px)
    width: 96px

a
  text-decoration: none
  color: black
  cursor: pointer

.rhap_container
  margin: 8px 0 !important
  padding: 0 !important

.center
  display: flex
  align-items: center
  justify-content: center

.rhap_repeat-button
  display: none !important

.rhap_play-pause-button
  color: $theme-yellow !important

.rhap_time
  color: white !important

.rhap_progress-indicator, .rhap_progress-filled
  background-color: $theme-yellow !important

.rhap_main-controls
  width: 100% !important

.rhap_main-controls-button
  height: 48px !important

.rhap_volume-controls
  display: none !important

.rhap_container
  background-color: transparent !important
  border: none !important
  box-shadow: none !important
  outline: none !important

.rhap_controls-section
  display: none !important
  outline: none !important

.rhap_time
  color: #2d2d2d !important

.rhap_progress-container, .rhap_progress-bar
  outline: none !important

.button
  &:hover
    text-decoration: none

video
  outline: 0

.fixed-ratio-image-container
  display: block
  width: 100%
  height: auto
  position: relative
  overflow: hidden
  padding: 75% 0 0 0

.fixed-ratio-image-container img
  display: block
  max-width: 100%
  max-height: 100%
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

div, img
  transition: all .15s ease-in-out

.full-center
  display: flex
  width: 100%
  height: 100vh
  justify-content: center
  align-items: center
  text-align: center

.mr8
  margin-right: 8px
