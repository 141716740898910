.podcast-page, .topic-list-page

  padding-top: 60px

  .nav-container-p
    display: flex
    height: 56px
    max-width: 1200px
    padding-right: 16px
    padding-left: 16px
    justify-content: flex-start
    align-items: center
    flex: 1

  .body
    height: 1800px
    background-color: #111

  .section
    height: 228px
    margin-top: 0
    background-color: #2c2c2c

  .container
    max-width: 1200px
    margin-top: 0

  .section-navigation
    height: 64px

  .container-2
    height: 228px
    max-width: 1200px
    padding-right: 12px
    padding-left: 12px
    text-align: left

  .podcaster-name
    margin-top: 0
    margin-bottom: 16px
    color: #fff
    font-size: 28px
    line-height: 140%

  .podcastor-name-wrap
    margin-left: 24px

  .podcaster-photo-detail
    width: 128px
    height: 128px
    border-radius: 40px

  .social-link-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -webkit-flex-direction: row
    -ms-flex-direction: row
    flex-direction: row
    -webkit-box-align: stretch
    -webkit-align-items: stretch
    -ms-flex-align: stretch
    align-items: stretch

  .social-link-box
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 48px
    height: 48px
    margin-right: 8px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    border-radius: 12px
    background-color: #4b4b4b
    -webkit-transition: all 200ms ease
    transition: all 200ms ease
    text-align: left

    &:hover
      background-color: #6f6f6f

  .podcaster-info-detail-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-top: 42px
    margin-bottom: 24px
    padding: 0
    -webkit-box-pack: start
    -webkit-justify-content: flex-start
    -ms-flex-pack: start
    justify-content: flex-start
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    border-style: none
    border-width: 2px
    border-color: #4b4b4b
    border-radius: 0

  .podcaster-photo
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 56px
    height: 56px
    margin-right: 12px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    border-style: solid
    border-width: 1px
    border-color: #ffdd15
    border-radius: 21px

    &.large-ring
      -webkit-box-flex: 0
      -webkit-flex: 0 auto
      -ms-flex: 0 auto
      flex: 0 auto
      width: auto
      height: auto
      margin-right: 0
      margin-bottom: 0
      padding: 4px
      border-width: 4px
      border-radius: 48px

  .social-logo
    width: 24px
    height: 24px

  .follow-button
    display: none
    width: 160px
    height: 64px
    padding: 22px 12px
    -webkit-box-pack: start
    -webkit-justify-content: flex-start
    -ms-flex-pack: start
    justify-content: flex-start
    -webkit-box-align: end
    -webkit-align-items: flex-end
    -ms-flex-align: end
    align-items: flex-end
    -webkit-align-self: auto
    align-self: auto
    -webkit-box-ordinal-group: 2
    -ms-flex-order: 1
    order: 1
    -webkit-box-flex: 0
    -webkit-flex: 0 auto
    -ms-flex: 0 auto
    flex: 0 auto
    border-radius: 24px
    background-color: #ffdd15

    &:hover
      background-color: #ceb000

  .text-block
    display: block
    margin-right: auto
    margin-left: auto
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    color: #000
    font-size: 24px
    font-weight: 700
    text-align: center

  .div-block
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 600px
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -webkit-flex-direction: row
    -ms-flex-direction: row
    flex-direction: row
    -webkit-flex-wrap: nowrap
    -ms-flex-wrap: nowrap
    flex-wrap: nowrap
    -webkit-align-content: flex-start
    align-content: flex-start
    -webkit-align-self: center
    align-self: center
    -webkit-box-ordinal-group: 2
    -ms-flex-order: 1
    order: 1
    -webkit-box-flex: 1
    -webkit-flex: 1
    -ms-flex: 1
    flex: 1
    direction: rtl

  .section-3
    height: 56px
    background-color: #2c2c2c

  .sub-nav-button-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    height: 56px
    -webkit-align-self: center
    align-self: center

  .sub-menu-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    height: 56px
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    -webkit-align-self: center
    align-self: center
    -webkit-box-flex: 1
    -webkit-flex: 1
    -ms-flex: 1
    flex: 1

  .nav-sub-menu
    height: 100%
    margin-right: 24px
    padding-top: 18px
    padding-bottom: 18px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-bottom: 3px solid #2d2d2d
    color: #aaa
    -webkit-transition: all 200ms ease
    transition: all 200ms ease
    line-height: 140%
    font-weight: 700
    text-decoration: none

    &:hover
      border-bottom-width: 4px
      border-bottom-color: #ffdd15
      color: #fff

    &.active
      border-style: none none solid
      border-width: 1px 1px 4px
      border-color: #000 #000 #ffdd15
      color: #fff

  .button
    padding: 15px 24px
    -webkit-align-self: flex-end
    align-self: flex-end
    border-radius: 12px
    background-color: #ffdd15
    -webkit-transition: all 200ms ease
    transition: all 200ms ease
    color: #111
    font-size: 16px
    line-height: 112.5%
    font-weight: 700
    text-align: center

    &:hover
      background-color: rgba(255, 221, 21, 0.8)

    &.small
      padding: 8px 12px
      -webkit-align-self: center
      -ms-grid-row-align: center
      align-self: center
      border-radius: 8px
      font-size: 14px

      &.mr8
        margin-right: 8px

    &.button-secondary
      border-style: solid
      border-width: 1px
      border-color: #fff
      background-color: transparent
      color: #fff

    &.full-width
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

      &.mr12
        max-width: 160px
        -webkit-box-pack: center
        -webkit-justify-content: center
        -ms-flex-pack: center
        justify-content: center

  .section-5
    margin-top: 60px

  .container-3
    max-width: 1200px
    padding-right: 0
    padding-left: 0

  .text-block-2
    color: #fff
    font-size: 18px
    font-weight: 700

  .div-block-2
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -webkit-flex-direction: row
    -ms-flex-direction: row
    flex-direction: row
    -webkit-box-align: stretch
    -webkit-align-items: stretch
    -ms-flex-align: stretch
    align-items: stretch
    -webkit-align-self: center
    align-self: center
    -webkit-box-flex: 1
    -webkit-flex: 1
    -ms-flex: 1
    flex: 1
    direction: rtl

  .cnannel-data-index
    direction: ltr
    color: #ccc
    font-size: 18px

  .text-block-3
    margin-right: 4px
    color: #fff
    font-size: 18px
    font-weight: 700

  .data-follow
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-right: 36px

  .data-follow-copy
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-right: 12px

  .data-listen
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-right: 0

  .channel-menu-wrap
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    height: 56px
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    -webkit-align-self: center
    align-self: center
    -webkit-box-flex: 1
    -webkit-flex: 1
    -ms-flex: 1
    flex: 1

  .section-title-underline
    width: 100%
    height: 1px
    background-color: #4b4b4b

  .cnannel-episode
    margin-top: 0
    margin-bottom: 8px
    background-color: transparent
    color: #fff
    font-size: 28px
    line-height: 140%

  .column-5
    padding-right: 20px
    padding-left: 20px

  .grades
    display: block
    margin-right: 8px
    margin-bottom: 0
    margin-left: 0
    padding: 3.5px 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-style: solid
    border-width: 1px
    border-color: #fff
    border-radius: 4px
    color: #fff
    font-size: 12px
    line-height: 140%

  .topic-nmae
    color: #fff
    font-size: 20px
    line-height: 140%
    font-weight: 700
    text-align: left
    text-transform: none

  .podcaster
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 8px
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -webkit-flex-direction: row
    -ms-flex-direction: row
    flex-direction: row
    -webkit-box-align: end
    -webkit-align-items: flex-end
    -ms-flex-align: end
    align-items: flex-end

  .a-topic
    margin: 12px 5px
    padding: 24px
    border-radius: 8px
    transition: all 0.15s ease-in-out
    border-style: solid
    border-width: 1px
    border-color: #2d2d2d
    background-color: #2d2d2d
    cursor: pointer
    flex: 0 0 calc(33.333% - 24px)

    .topic-banner
      object-fit: cover
      width: 100%
      height: 100%
      margin-bottom: 0
      border-radius: 16px 16px 0 0

    .topic-name
      height: auto
      display: flex
      align-items: center
      max-height: 56px
      margin-bottom: 12px
      color: #fff
      font-size: 20px
      line-height: 140%
      font-weight: 700
      text-align: left
      text-transform: none

    @media screen and (max-width: 991px)
      flex: 0 0 calc(50% - 24px)

    .topic-banner-wrapper
      width: 100%

    &:hover
      border-color: #6f6f6f

      .topic-name
        text-decoration: underline

      .topic-banner
        @media (min-width: 495px)
          transform: scale(1.1)

  .podcaster-s-name
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-right: auto
    margin-left: 12px
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -webkit-flex-direction: row
    -ms-flex-direction: row
    flex-direction: row
    -webkit-box-pack: start
    -webkit-justify-content: flex-start
    -ms-flex-pack: start
    justify-content: flex-start
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    -webkit-align-self: center
    align-self: center
    color: #ccc
    font-size: 14px
    font-weight: 700
    text-align: left

  .columns-1202
    max-width: 1200px
    margin-right: auto
    margin-left: auto
    text-align: center

  .tag-of-topic
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-right: auto
    margin-bottom: 0
    margin-left: auto
    -webkit-box-align: end
    -webkit-align-items: flex-end
    -ms-flex-align: end
    align-items: flex-end

  .div-block-15
    height: 68.5px
    margin-bottom: 12px

  .tag-3
    display: block
    margin-right: 4px
    margin-bottom: 0
    margin-left: 0
    padding: 3.5px 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-style: solid
    border-width: 1px
    border-color: #fff
    border-radius: 4px
    color: #fff
    font-size: 12px
    line-height: 140%

    &.yellow
      margin-right: 8px
      margin-left: 0
      border-style: none
      border-color: #ffdd15
      background-color: #ffdd15
      color: #000
      font-weight: 700

  .topic-banner
    width: 211px
    height: 158.25px
    margin-bottom: 16px
    border-radius: 8px

  .podcaster-logo
    display: block
    width: 32px
    height: 32px
    margin-right: 0
    margin-left: 0
    border-radius: 12px
    text-align: left

  .title-underline
    width: 100%
    height: 1px
    margin-bottom: 36px
    background-color: #4b4b4b

  .section-4
    margin-top: 48px

  .episode
    margin-bottom: 12px
    padding: 16px
    border-style: solid
    border-width: 2px
    border-color: #4b4b4b
    border-radius: 4px
    background-color: #2d2d2d
    cursor: pointer
    transition: all 0.15s ease-in-out

    &:hover
      background-color: #111

  .heading-2
    margin-top: auto
    margin-bottom: 8px
    color: #fff
    font-size: 16px

  .filled-white
    display: block
    margin-right: 8px
    margin-bottom: 0
    margin-left: 0
    padding: 3.5px 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-style: none
    border-width: 1px
    border-color: #fff
    border-radius: 4px
    background-color: #fff
    color: #000
    font-size: 12px
    line-height: 140%
    font-weight: 700

  .div-block-10
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    height: auto
    margin-bottom: 12px

  .channel-episode-list
    width: 1200px
    margin-right: 0
    margin-left: 0

  .channel-topic-list
    margin: 0 0 36px
    padding-left: 0
    text-align: center
    @media (min-width: 479px)
      display: none

  .image-5
    width: 64px
    height: 64px
    margin-right: 16px
    border-radius: 8px

  .div-block-11
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center

  .div-block-12
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center

  .topic-price
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 16px

  .price-01
    margin-right: 12px
    color: #ffdd15
    font-size: 20px
    font-weight: 700

  .price-02
    color: #b0b0b0
    font-size: 20px
    font-weight: 400
    text-decoration: line-through

  .div-block-16
    height: 68.5px
    margin-bottom: 12px

  .own-topic
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-right: auto
    margin-bottom: 12px
    margin-left: auto
    -webkit-box-align: end
    -webkit-align-items: flex-end
    -ms-flex-align: end
    align-items: flex-end

  .tag-6
    display: block
    margin-right: 4px
    margin-bottom: 0
    margin-left: 0
    padding: 3.5px 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-style: solid
    border-width: 1px
    border-color: #ffdd15
    border-radius: 4px
    color: #ffdd15
    font-size: 12px
    line-height: 140%

    &.yellow
      margin-right: 0
      margin-left: 0
      border-style: none
      border-color: #ffdd15
      background-color: #ffdd15
      color: #000
      font-weight: 700

  .div-block-3
    display: none
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -webkit-flex-direction: row
    -ms-flex-direction: row
    flex-direction: row
    -webkit-box-align: stretch
    -webkit-align-items: stretch
    -ms-flex-align: stretch
    align-items: stretch
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    -webkit-box-flex: 1
    -webkit-flex: 1
    -ms-flex: 1
    flex: 1
    direction: rtl

  .columns-1203
    display: block

  .tag-7
    display: block
    margin-right: 8px
    margin-bottom: 0
    margin-left: 0
    padding: 3.5px 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-style: solid
    border-width: 1px
    border-color: #ffdd15
    border-radius: 4px
    background-color: #ffdd15
    color: #000
    font-size: 12px
    line-height: 140%
    font-weight: 700
    text-align: left

    &.yellow
      margin-right: 0
      margin-left: 0
      border-style: none
      border-color: #ffdd15
      background-color: #ffdd15
      color: #000
      font-weight: 700

  .div-block-18
    display: none

  .follow-button-copy
    display: none
    width: 160px
    height: 64px
    padding: 22px 12px
    -webkit-box-pack: start
    -webkit-justify-content: flex-start
    -ms-flex-pack: start
    justify-content: flex-start
    -webkit-box-align: end
    -webkit-align-items: flex-end
    -ms-flex-align: end
    align-items: flex-end
    -webkit-align-self: auto
    align-self: auto
    -webkit-box-ordinal-group: 2
    -ms-flex-order: 1
    order: 1
    -webkit-box-flex: 0
    -webkit-flex: 0 auto
    -ms-flex: 0 auto
    flex: 0 auto
    border-radius: 24px
    background-color: #ffdd15

    &:hover
      background-color: #ceb000

  .div-block-19
    display: block
    margin-top: -380px
    margin-bottom: 0
    margin-left: -12px
    grid-auto-columns: 1fr
    -ms-grid-columns: 1fr 1fr
    grid-template-columns: 1fr 1fr
    -ms-grid-rows: auto auto
    grid-template-rows: auto auto
    background-color: transparent
    color: transparent

  .text-block-8
    width: 96px
    margin-top: 8px
    padding: 4px 12px
    border-top-right-radius: 4px
    border-bottom-right-radius: 4px
    background-color: red
    color: #fff
    font-weight: 700
    text-align: left

  .tag-sales
    display: block
    width: 96px
    margin-right: 0
    margin-bottom: 0
    margin-left: 0
    padding: 3.5px 12px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-style: none
    border-width: 1px
    border-color: #ffdd15
    border-radius: 0 4px 4px 0
    background-color: #ff1515
    color: #fff
    font-size: 12px
    line-height: 140%
    font-weight: 700
    text-align: left

    &.yellow
      margin-right: 0
      margin-left: 0
      border-style: none
      border-color: #ffdd15
      background-color: #ffdd15
      color: #000
      font-weight: 700

  .body-2
    background-color: #111

  .section-6
    margin: 48px 0

  .panel-base
    margin-right: 12px
    margin-left: 12px
    padding: 40px
    border-style: solid
    border-width: 1px
    border-color: #4b4b4b
    border-radius: 24px
    background-color: #2d2d2d
    box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.3)
    -webkit-transition: all 200ms ease
    transition: all 200ms ease

    &.flex-grow
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

  .section-title-wrap
    width: 100%
    margin-bottom: 24px

  .section-title
    margin-top: 0
    margin-bottom: 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    color: #fff
    font-size: 28px
    line-height: 140%

  .section-body-text
    margin-bottom: 0
    color: #ccc
    font-size: 16px
    line-height: 160%

  .section-7
    display: block
    height: 60px

  .container-4, .container-5
    max-width: 1200px
    padding-right: 12px
    padding-left: 12px

  .body-3
    background-color: #111
    font-size: 24px

  .container-6
    display: block
    max-width: 1200px
    margin-right: auto
    margin-left: auto
    padding-right: 12px
    padding-left: 12px
    text-align: left

  .section-8
    margin-top: 142px

  .home-title
    margin-top: 0
    margin-bottom: 96px
    padding-top: 12px
    padding-bottom: 12px
    color: #fff
    font-size: 60px
    font-weight: 700
    text-align: center

  .link-block
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    padding: 14px 24px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    -webkit-align-self: center
    align-self: center
    border-style: solid
    border-width: 1px
    border-color: #fff
    border-radius: 12px
    text-decoration: none

    &.button-scondary
      width: 160px
      margin-top: 0
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-transition: all 200ms ease
      transition: all 200ms ease

  .button-text
    margin-right: 4px
    margin-bottom: 0
    color: #fff
    font-size: 16px
    line-height: 112.5%
    font-weight: 700

  .icon-box-20
    width: 20px
    height: 20px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center

  .image-3
    width: 100%
    height: 100%

  .home-subtitle-block
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-top: 0
    margin-bottom: 60px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center

  .columns-1204
    margin-top: 48px
    margin-right: 12px
    margin-left: 12px

  .image-6
    width: 600px
    padding-right: 12px
    padding-left: 12px
    border-radius: 40px

  .section-9
    margin-top: 160px
    padding-right: 12px
    padding-left: 12px

  .container-7
    display: block
    max-width: 1200px
    padding-right: 12px
    padding-left: 12px

  .home-subtitle
    margin-top: 12px
    margin-bottom: 36px
    padding-top: 12px
    padding-bottom: 12px
    color: #ffdd15
    font-size: 48px
    font-weight: 700
    text-align: center

  .home-title-block
    display: none
    margin-top: 48px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center

  .section-10
    height: 120px

  .container-podcaster
    max-width: 1440px
    padding-right: 12px
    padding-left: 12px

  .image-7
    padding-top: 0
    padding-right: 12px
    padding-left: 12px
    border-radius: 24px

  .image-8
    margin-bottom: 12px
    border-radius: 38px

  .columns-1205
    margin-top: 0

  .div-block-20
    border: 1px none #000
    border-radius: 20px
    background-color: #2c2c2c

  .vip-podcaster
    margin-bottom: 32px
    padding: 24px
    border-style: solid
    border-width: 4px
    border-color: #2d2d2d
    border-radius: 36px
    background-color: #111

    &:hover
      background-color: #000

  .podcaster-photo-detail-2
    width: auto
    height: auto
    border-radius: 40px

  .text-block-9
    margin-bottom: 16px
    padding-left: 24px
    color: #fff
    font-size: 24px
    font-weight: 700

  .column-14
    margin-top: 24px
    margin-bottom: 24px
    padding-left: 0

  .text-block-9-copy
    margin-bottom: 38px
    padding-left: 24px
    color: #ccc
    font-size: 16px
    line-height: 24px
    font-weight: 400

  .column-15
    padding-right: 16px
    padding-left: 12px

  .list
    padding-left: 0

  .column-16
    padding-right: 12px
    padding-left: 16px

  .div-block-21
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    padding-left: 24px
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center

  .text-block-10
    color: #fff
    font-size: 16px

  .image-9
    margin-right: 8px

  .slider
    margin-top: 48px

  .mask
    width: 300px

  .slide
    margin-right: 40px

  .text-block-11
    display: block
    width: 640px
    -webkit-box-pack: end
    -webkit-justify-content: flex-end
    -ms-flex-pack: end
    justify-content: flex-end
    color: #fff
    font-size: 20px
    line-height: 32px
    font-weight: 700
    text-align: center

  .div-block-22
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 36px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center

  .text-span, .text-span-2, .text-span-3
    color: #ffdd15

  .podcast-app-slider
    height: 560px
    margin-top: 64px
    padding-right: 12px
    padding-left: 12px
    background-color: transparent

  .podcast-app-pic
    overflow: visible
    width: 320px
    height: 480px
    margin-right: 36px
    padding-right: 0
    padding-bottom: 0

  .mask-2
    padding-right: 64px
    padding-left: 64px

  .text-block-12
    margin-top: 8px
    margin-bottom: 24px
    color: #fff
    font-weight: 700
    text-align: center

  .div-block-23
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    height: 60px
    margin-top: 12px
    margin-bottom: 28px
    padding-right: 4px
    padding-left: 4px
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -webkit-flex-direction: row
    -ms-flex-direction: row
    flex-direction: row
    -webkit-box-pack: start
    -webkit-justify-content: flex-start
    -ms-flex-pack: start
    justify-content: flex-start
    -webkit-flex-wrap: wrap
    -ms-flex-wrap: wrap
    flex-wrap: wrap
    -webkit-box-align: start
    -webkit-align-items: flex-start
    -ms-flex-align: start
    align-items: flex-start
    -webkit-align-content: flex-start
    align-content: flex-start

  .list-item
    margin-bottom: 0

  .vip-podcast-block
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 60px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center

  .column-17
    display: block
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center

  .div-block-24
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center

  .columns-1206
    margin-right: 0
    margin-left: 0

  .div-block-25
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    padding-right: 8px
    -webkit-box-pack: end
    -webkit-justify-content: flex-end
    -ms-flex-pack: end
    justify-content: flex-end

  .div-block-26
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    padding-left: 8px

  .columns-1207
    margin-right: 12px
    margin-bottom: 60px
    margin-left: 12px

  .div-block-27
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 60px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center

  .list-item-2
    padding: 12px

  .columns-1208
    width: 100%

  .button-home-page
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    width: 240px
    height: 72px
    padding: 15px 24px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center
    -webkit-align-self: flex-end
    align-self: flex-end
    border-style: solid
    border-width: 1px
    border-color: #fff
    border-radius: 16px
    background-color: transparent
    -webkit-transition: all 200ms ease
    transition: all 200ms ease
    color: #fff
    font-size: 24px
    line-height: 112.5%
    font-weight: 700
    text-align: center

    &:hover
      background-color: #000

    &.small
      padding: 8px 12px
      -webkit-align-self: center
      -ms-grid-row-align: center
      align-self: center
      border-radius: 8px
      font-size: 14px

      &.mr8
        margin-right: 8px

    &.button-secondary
      border-style: solid
      border-width: 1px
      border-color: #fff
      background-color: transparent
      color: #fff

    &.full-width
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

      &.mr12
        max-width: 160px
        -webkit-box-pack: center
        -webkit-justify-content: center
        -ms-flex-pack: center
        justify-content: center

    &.button-left
      background-color: transparent

    &.left-button
      margin-right: 24px

  .div-block-28
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-pack: end
    -webkit-justify-content: flex-end
    -ms-flex-pack: end
    justify-content: flex-end

  .div-block-29
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-pack: start
    -webkit-justify-content: flex-start
    -ms-flex-pack: start
    justify-content: flex-start

  .div-block-30
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center

  .image-11
    height: 580px

  .div-block-31
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-box-pack: end
    -webkit-justify-content: flex-end
    -ms-flex-pack: end
    justify-content: flex-end
    -webkit-box-align: start
    -webkit-align-items: flex-start
    -ms-flex-align: start
    align-items: flex-start

  .slider-2
    height: 510px
    background-color: #111

  .div-block-32
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    height: 100%
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center

  .slide-2
    height: 510px
    padding-right: 48px
    padding-left: 48px

  .image-12
    height: 100%

  .right-arrow
    margin-left: 12px
    padding-right: 0
    padding-left: 0

  .div-block-33
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    height: 100%
    padding-right: 36px
    padding-left: 36px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center

  .slide-nav
    display: block

  .mask-3
    height: 510px

  .div-block-34
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 60px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -webkit-align-items: center
    -ms-flex-align: center
    align-items: center

  .button-left
    padding: 15px 24px
    -webkit-align-self: flex-end
    align-self: flex-end
    border-radius: 12px
    background-color: #ffdd15
    -webkit-transition: all 200ms ease
    transition: all 200ms ease
    color: #111
    font-size: 16px
    line-height: 112.5%
    font-weight: 700
    text-align: center

    &:hover
      background-color: rgba(255, 221, 21, 0.8)

    &.small
      padding: 8px 12px
      -webkit-align-self: center
      -ms-grid-row-align: center
      align-self: center
      border-radius: 8px
      font-size: 14px

      &.mr8
        margin-right: 8px

    &.button-secondary
      border-style: solid
      border-width: 1px
      border-color: #fff
      background-color: transparent
      color: #fff

    &.full-width
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

      &.mr12
        height: 60px
        max-width: 240px
        margin-right: 24px
        -webkit-box-pack: center
        -webkit-justify-content: center
        -ms-flex-pack: center
        justify-content: center
        -webkit-align-self: stretch
        -ms-grid-row-align: stretch
        align-self: stretch
        font-size: 24px

  .div-block-49
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 0
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center

  .div-block-22-copy
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    margin-bottom: 60px
    -webkit-box-pack: center
    -webkit-justify-content: center
    -ms-flex-pack: center
    justify-content: center

  .number-of-purchasers
    display: block
    margin-right: 8px
    margin-bottom: 0
    margin-left: 0
    padding: 3.5px 8px
    -webkit-align-self: center
    -ms-grid-row-align: center
    align-self: center
    border-style: solid
    border-width: 1px
    border-color: #fff
    border-radius: 4px
    color: #fff
    font-size: 12px
    line-height: 140%

  .text-block-7-copy
    display: none
    color: #b0b0b0
    font-size: 20px
    font-weight: 400
    text-decoration: none

  .div-block-51
    padding: 4px
    border-style: solid
    border-width: 3px
    border-color: #ffdd15
    border-radius: 46px

  @media screen and (max-width: 991px)
    .container-2
      padding-right: 24px
      padding-left: 24px

    .podcaster-photo.large-ring
      -webkit-box-flex: 0
      -webkit-flex: 0 auto
      -ms-flex: 0 auto
      flex: 0 auto
      width: 200px

    .section-5
      margin-top: 36px

    .container-3
      padding-right: 0
      padding-left: 0

    .cnannel-episode
      text-align: center

    .topic-nmae
      margin-bottom: 0

    .podcaster
      margin-bottom: 8px

    .a-topic
      height: auto
      margin-bottom: 24px

    .div-block-15
      height: 60px
      margin-bottom: 24px

    .topic-banner
      width: 100%
      height: auto

    .div-block-16
      height: 60px
      margin-bottom: 32px

    .menu-button-2
      padding: 6px
      border-radius: 8px
      background-color: #fff

    .section-body-text
      font-size: 16px

    .section-7
      display: block
      height: 60px

    .container-5
      padding-right: 12px
      padding-left: 12px

    .section-8
      margin-bottom: 120px

    .section-9
      margin-top: 0
      margin-bottom: 120px
      padding-right: 12px

    .text-block-9
      padding-right: 24px
      padding-left: 0

    .column-14
      padding-right: 0
      padding-left: 0

    .text-block-9-copy, .div-block-21
      padding-right: 24px
      padding-left: 0

    .text-block-11
      width: 80%

    .div-block-23
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-align-content: flex-start
      align-content: flex-start

    .div-block-27
      margin-bottom: 48px

    .slider-2
      margin-bottom: 60px

    .column-19
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-align-self: auto
      align-self: auto
      -webkit-box-flex: 0
      -webkit-flex: 0 auto
      -ms-flex: 0 auto
      flex: 0 auto

    .column-20, .column-21
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .column-22
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-box-align: stretch
      -webkit-align-items: stretch
      -ms-flex-align: stretch
      align-items: stretch

    .div-block-36, .div-block-37
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .div-block-38
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-align: center
      -webkit-align-items: center
      -ms-flex-align: center
      align-items: center

    .div-block-39, .div-block-40, .div-block-42
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .columns-1209
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-align: center
      -webkit-align-items: center
      -ms-flex-align: center
      align-items: center

    .list-item-3
      display: block
      list-style-type: disc

    .columns-1210, .columns-1211, .columns-1212
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-align: center
      -webkit-align-items: center
      -ms-flex-align: center
      align-items: center

    .div-block-49
      margin-bottom: 0

    .list-item-4
      display: block
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

  @media screen and (max-width: 767px)
    .section, .container-2
      height: auto

    .podcaster-photo-detail
      width: 108px
      height: 108px

    .podcaster-info-detail-wrap
      margin-bottom: 16px
      padding: 12px 0

    .podcaster-photo.large-ring
      width: 120px
      height: 120px
      border-radius: 40px

    .follow-button
      display: none
      height: 56px
      padding-top: 12px
      padding-bottom: 12px
      -webkit-box-pack: start
      -webkit-justify-content: flex-start
      -ms-flex-pack: start
      justify-content: flex-start
      -webkit-box-align: center
      -webkit-align-items: center
      -ms-flex-align: center
      align-items: center
      -webkit-align-self: center
      -ms-grid-row-align: center
      align-self: center
      border-radius: 20px

    .text-block
      font-size: 20px

    .sub-nav-button-wrap
      display: none

    .button
      &.button-secondary
        -webkit-box-flex: 0
        -webkit-flex: 0 auto
        -ms-flex: 0 auto
        flex: 0 auto

      &.full-width.mr12
        width: 100%
        margin-right: 0
        margin-bottom: 12px

    .container-3
      padding-right: 0
      padding-left: 0

    .channel-menu-wrap
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex

    .menu-button-2
      overflow: hidden
      padding: 0
      border-radius: 8px
      background-color: #111

      &.w--open
        overflow: hidden
        border-radius: 8px
        background-color: #ececec

    .div-block-17
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex

    .div-block-18
      display: block
      height: auto
      margin-bottom: 24px

    .follow-button-copy
      display: none
      height: 56px
      padding-top: 12px
      padding-bottom: 12px
      -webkit-box-pack: start
      -webkit-justify-content: flex-start
      -ms-flex-pack: start
      justify-content: flex-start
      -webkit-box-align: center
      -webkit-align-items: center
      -ms-flex-align: center
      align-items: center
      -webkit-align-self: center
      -ms-grid-row-align: center
      align-self: center
      border-radius: 20px

    .panel-base
      padding: 32px
      -webkit-box-ordinal-group: 1
      -ms-flex-order: 0
      order: 0
      border-radius: 12px

      &.flex-grow
        margin-bottom: 0
        -webkit-box-ordinal-group: 2
        -ms-flex-order: 1
        order: 1

    .section-body-text
      font-size: 16px

    .section-7
      display: block

    .link-block.button-scondary
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

    .button-home-page
      &.button-secondary
        -webkit-box-flex: 0
        -webkit-flex: 0 auto
        -ms-flex: 0 auto
        flex: 0 auto

      &.full-width.mr12
        width: 100%
        margin-right: 0
        margin-bottom: 12px

    .div-block-28, .div-block-29
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .button-left
      &.button-secondary
        -webkit-box-flex: 0
        -webkit-flex: 0 auto
        -ms-flex: 0 auto
        flex: 0 auto

      &.full-width.mr12
        width: 100%
        margin-right: 0
        margin-bottom: 12px

  @media screen and (max-width: 479px)
    .w-col
      padding: 0

    .nav-container-p
      display: -ms-grid
      height: auto
      max-width: 100%
      -webkit-box-pack: start
      -webkit-justify-content: flex-start
      -ms-flex-pack: start
      justify-content: flex-start
      grid-auto-flow: row
      grid-auto-columns: 1fr
      grid-column-gap: 12px
      grid-row-gap: 12px
      grid-template-areas: ".." "Area-2 Area-3"
      -ms-grid-columns: 1fr 12px 1fr
      grid-template-columns: 1fr 1fr
      -ms-grid-rows: 1fr 12px auto
      grid-template-rows: 1fr auto

    .section
      height: auto
      padding-top: 0

    .container-2
      height: auto
      max-width: 100%

    .podcaster-name
      width: auto
      margin-bottom: 12px
      padding-right: 20px
      padding-left: 20px
      font-size: 24px
      text-align: center

    .podcastor-name-wrap
      width: 280px
      margin-left: 0

    .podcaster-photo-detail
      width: 108px
      height: 108px
      border-radius: 40px

    .social-link-wrap
      margin-top: 12px
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center
      text-align: left

    .social-link-box.right-bax
      margin-right: 0

    .podcaster-info-detail-wrap
      margin-top: 42px
      margin-bottom: 0
      padding: 0
      -webkit-box-orient: vertical
      -webkit-box-direction: normal
      -webkit-flex-direction: column
      -ms-flex-direction: column
      flex-direction: column

    .podcaster-photo.large-ring
      width: 128px
      height: 128px
      margin-right: 0
      margin-bottom: 0
      border-width: 2px

    .follow-button
      display: none
      width: 160px
      height: 48px
      padding-top: 12px
      padding-bottom: 12px
      border-radius: 16px
      line-height: 20px

    .text-block
      -webkit-align-self: center
      -ms-grid-row-align: center
      align-self: center
      font-size: 20px

    .div-block
      width: 100%
      margin-top: 24px
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .section-3
      height: auto

    .sub-menu-wrap
      overflow: hidden
      -webkit-flex-wrap: nowrap
      -ms-flex-wrap: nowrap
      flex-wrap: nowrap
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

    .nav-sub-menu
      height: auto
      padding-top: 8px
      padding-bottom: 8px
      -webkit-box-flex: 0
      -webkit-flex: 0 0 auto
      -ms-flex: 0 0 auto
      flex: 0 0 auto
      text-align: center

    .button.full-width.mr12
      max-width: 240px
      margin-bottom: 0

    .section-5
      margin-top: 36px

    .container-3
      padding-right: 0
      padding-left: 0

    .div-block-2
      display: none
      -webkit-align-self: auto
      align-self: auto
      direction: rtl
      text-align: center

    .data-follow
      margin-right: 0
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .data-listen
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .channel-menu-wrap
      display: none
      overflow: hidden
      -webkit-flex-wrap: nowrap
      -ms-flex-wrap: nowrap
      flex-wrap: nowrap
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

    .cnannel-episode
      margin-bottom: 12px
      font-size: 20px
      text-align: center

    .column-5
      display: block
      padding-right: 0
      padding-left: 0
      grid-auto-flow: row
      grid-auto-columns: 1fr
      -ms-grid-columns: 1fr
      grid-template-columns: 1fr
      -ms-grid-rows: auto
      grid-template-rows: auto

    .grades
      display: block
      margin-top: 0
      margin-right: 8px
      margin-bottom: 0

    .topic-nmae
      margin-bottom: 0
      font-size: 16px

    .podcaster
      display: block
      max-width: none
      margin-bottom: 4px

    .podcaster-s-name
      margin-left: 0
      font-weight: 400

    .tag-of-topic
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      overflow: visible
      margin-right: 0
      margin-bottom: 0
      margin-left: 0
      -webkit-flex-wrap: wrap
      -ms-flex-wrap: wrap
      flex-wrap: wrap

    .div-block-15
      display: flex
      align-items: center
      height: auto
      margin-bottom: 8px

    .tag-3
      margin-top: 4px
      margin-right: 4px
      margin-bottom: 4px

    .topic-banner
      width: 108px
      height: 81px
      max-width: none
      margin-bottom: 0

    .podcaster-logo
      display: none

    .title-underline
      margin-bottom: 16px

    .section-4
      margin-top: 24px

    .filled-white
      margin-top: 4px
      margin-right: 4px
      margin-bottom: 4px

    .channel-topic-list
      margin-top: 0

    .topic-price
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      margin-bottom: 0
      -webkit-box-align: center
      -webkit-align-items: center
      -ms-flex-align: center
      align-items: center

    .price-01
      margin-right: 12px
      font-size: 16px

    .price-02
      display: none
      margin-right: 8px
      color: #fff
      font-size: 16px
      text-decoration: line-through

    .own-topic
      margin-bottom: 8px
      -webkit-flex-wrap: wrap
      -ms-flex-wrap: wrap
      flex-wrap: wrap

    .tag-6
      margin-top: 4px
      margin-right: 4px
      margin-bottom: 4px

    .nav-container-2
      -webkit-box-pack: start
      -webkit-justify-content: flex-start
      -ms-flex-pack: start
      justify-content: flex-start

    .div-block-3
      display: -ms-grid
      width: 100%
      margin-top: 24px
      justify-items: center
      -webkit-box-align: stretch
      -webkit-align-items: stretch
      -ms-flex-align: stretch
      align-items: stretch
      -ms-grid-row-align: center
      align-self: center
      grid-auto-columns: 1fr
      grid-column-gap: 16px
      grid-row-gap: 8px
      -ms-grid-columns: 1fr
      grid-template-columns: 1fr
      -ms-grid-rows: auto auto
      grid-template-rows: auto auto
      direction: rtl
      text-align: center

    .column-6, .column-7, .column-8, .column-9, .column-10, .column-11, .column-12, .column-13
      padding-right: 0
      padding-left: 0

    .tag-7
      margin-top: 4px
      margin-right: 8px
      margin-bottom: 4px

    .div-block-18
      display: none

    .follow-button-copy
      display: block
      width: 160px
      height: 48px
      padding-top: 12px
      padding-bottom: 12px
      border-radius: 16px
      line-height: 20px

    .tag-sales
      margin-top: 4px
      margin-right: 8px
      margin-bottom: 4px

    .section-6
      margin-top: 24px

    .panel-base
      margin-right: 8px
      margin-left: 8px
      padding: 24px

      &.flex-grow
        margin-bottom: 0

    .section-title
      font-size: 28px

    .section-7
      display: block
      height: 60px

    .container-6
      max-width: none

    .section-8
      margin-top: 36px
      margin-bottom: 36px

    .home-title
      display: block
      width: 80%
      margin-bottom: 0
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center
      font-size: 48px
      line-height: 56px

    .home-subtitle-block
      margin-bottom: 28px

    .section-9
      margin-top: 0
      margin-bottom: 48px
      padding-right: 0
      padding-left: 0

    .home-subtitle
      margin-bottom: 16px
      font-size: 28px
      line-height: 36px

    .vip-podcaster
      display: block
      margin-bottom: 24px
      padding: 16px 12px
      border-radius: 24px

    .podcaster-photo-detail-2
      border-radius: 34px

    .text-block-9
      padding-right: 0
      padding-left: 0
      text-align: center

    .column-14
      margin-top: 0
      margin-bottom: 0

    .text-block-9-copy
      margin-bottom: 16px
      padding-right: 0
      padding-left: 0
      text-align: center

    .column-15, .column-16
      padding-right: 0
      padding-left: 0

    .div-block-21
      padding-right: 0
      padding-left: 0
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .text-block-11
      width: auto
      margin-bottom: 0
      padding-right: 12px
      padding-left: 12px
      font-size: 16px
      line-height: 22px

    .div-block-22
      margin-bottom: 36px

    .div-block-23
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .list-item
      margin-bottom: 28px

    .vip-podcast-block
      margin-bottom: 36px

    .column-17
      padding-right: 0
      padding-left: 0

    .div-block-25
      display: block
      padding-right: 0
      -webkit-box-align: center
      -webkit-align-items: center
      -ms-flex-align: center
      align-items: center

    .div-block-26
      display: block
      padding-left: 0
      -webkit-box-align: center
      -webkit-align-items: center
      -ms-flex-align: center
      align-items: center

    .div-block-27
      margin-bottom: 28px

    .list-item-2
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      padding-top: 0
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .columns-1208
      width: auto

    .button-home-page
      width: 240px
      height: 60px
      max-width: 240px
      font-size: 20px

      &.left-button
        display: none

    .div-block-28
      display: block
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center
      font-size: 16px

    .div-block-29
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .column-18
      margin-bottom: 0
      padding-right: 0
      padding-left: 0

    .slider-2
      height: auto
      margin-bottom: 16px

    .slide-2
      height: auto

    .right-arrow
      margin-right: -24px
      margin-left: 0

    .div-block-33
      overflow: auto
      height: auto
      padding-right: 0
      padding-left: 0

    .mask-3
      height: auto

    .column-19
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      margin-bottom: 24px
      padding-right: 0
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-box-align: center
      -webkit-align-items: center
      -ms-flex-align: center
      align-items: center
      -webkit-align-self: flex-start
      align-self: flex-start
      -webkit-box-flex: 1
      -webkit-flex: 1
      -ms-flex: 1
      flex: 1

    .column-20, .column-21, .column-22
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      margin-bottom: 24px
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .div-block-34
      margin-bottom: 48px

    .button-left.full-width.mr12
      display: none
      max-width: none
      margin-right: 8px
      margin-bottom: 0

    .list-2
      margin-bottom: 0

    .list-3
      margin-bottom: 0
      padding-left: 0

    .div-block-35
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      margin-bottom: 28px
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .left-arrow
      margin-left: -24px

    .div-block-36
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .div-block-37, .div-block-38
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-box-align: stretch
      -webkit-align-items: stretch
      -ms-flex-align: stretch
      align-items: stretch

    .div-block-39, .div-block-40, .div-block-41, .div-block-42, .div-block-43
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -webkit-justify-content: center
      -ms-flex-pack: center
      justify-content: center

    .columns-1209, .columns-1210, .columns-1211, .columns-1212, .list-4, .list-5
      display: block

    .div-block-49
      margin-bottom: 0

    .div-block-22-copy
      margin-bottom: 48px

    .topic-info-2
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      width: 100%
      margin-left: 0
      margin-top: 16px
      -webkit-box-pack: start
      -webkit-justify-content: flex-start
      -ms-flex-pack: start
      justify-content: flex-start
      -webkit-flex-wrap: wrap
      -ms-flex-wrap: wrap
      flex-wrap: wrap
      -webkit-box-align: center
      -webkit-align-items: center
      -ms-flex-align: center
      align-items: center
      direction: ltr

    .number-of-purchasers
      margin-top: 0
      margin-right: 8px
      margin-bottom: 0

    .topic-info-1
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      margin-bottom: 16px

    .text-block-7-copy
      display: none
      margin-right: 8px
      color: #fff
      font-size: 14px
      text-decoration: none

    .div-block-51
      margin-bottom: 16px

  @media screen and (max-width: 479px)
    #w-node-cf7b249d-d0dd-1028-bd5c-75b76c54a708-af8761cf
      -webkit-align-self: auto
      align-self: auto
      -ms-grid-column-align: center
      justify-self: center

    #w-node-cf7b249d-d0dd-1028-bd5c-75b76c54a703-af8761cf
      -ms-grid-column-align: center
      justify-self: center

    #w-node-_616ce6c7-ef15-ecc5-76c5-4eb05cdbd1a6-af8761cf
      grid-area: Area-2

    #w-node-_12b8c467-d5a0-2553-b844-59b8fbcd3095-af8761cf
      grid-area: Area-3

    #w-node-_764a12c6-0961-beca-8136-b3379e526fe1-7885ef48
      -webkit-align-self: auto
      align-self: auto
      -ms-grid-column-align: center
      justify-self: center

    #w-node-_764a12c6-0961-beca-8136-b3379e526fe6-7885ef48
      -ms-grid-column-align: center
      justify-self: center

    #w-node-b1616f6a-8025-c840-82c4-b0fa20e267d4-7885ef48
      grid-area: Area-2

    #w-node-b1616f6a-8025-c840-82c4-b0fa20e267d6-7885ef48
      grid-area: Area-3

  @media screen and (max-width: 479px)
    #w-node-_616ce6c7-ef15-ecc5-76c5-4eb05cdbd1a6-af8761cf
      -ms-grid-row: 3
      -ms-grid-column: 1

    #w-node-_12b8c467-d5a0-2553-b844-59b8fbcd3095-af8761cf
      -ms-grid-row: 3
      -ms-grid-column: 3

    #w-node-b1616f6a-8025-c840-82c4-b0fa20e267d4-7885ef48
      -ms-grid-row: 3
      -ms-grid-column: 1

    #w-node-b1616f6a-8025-c840-82c4-b0fa20e267d6-7885ef48
      -ms-grid-row: 3
      -ms-grid-column: 3

  .a-topic
    width: 368px
    @media (max-width: 479px)
      width: calc(100% - 24px)
    height: auto
    margin: 12px 12px
    padding: 0
    border-radius: 16px
    background-color: #2d2d2d

    &:hover
      border-style: solid
      border-width: 1px
      border-color: #686868


  @media screen and (min-width: 479px)

    .list-6
      margin: -12px
      display: flex
      flex-wrap: wrap
      justify-content: flex-start
      list-style-type: disc

    .podcaster-s-name
      display: -webkit-box
      display: -webkit-flex
      display: -ms-flexbox
      display: flex
      margin-right: auto
      margin-left: 12px
      -webkit-box-orient: horizontal
      -webkit-box-direction: normal
      -webkit-flex-direction: row
      -ms-flex-direction: row
      flex-direction: row
      -webkit-box-pack: start
      -webkit-justify-content: flex-start
      -ms-flex-pack: start
      justify-content: flex-start
      -webkit-box-align: center
      -webkit-align-items: center
      -ms-flex-align: center
      align-items: center
      -webkit-align-self: center
      align-self: center
      color: #ccc
      font-size: 14px
      font-weight: 700
      text-align: left

  .div-block-50
    padding: 24px

    .title-and-description
      height: 140px
      overflow: hidden

      .topic-description
        margin-bottom: 16px
        color: #b0b0b0
        font-size: 16px
        line-height: 140%
        font-weight: 400
        text-align: left
        text-transform: none

        &:hover
          text-decoration: none
