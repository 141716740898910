.simple-episode-player
  border-color: #FFDD15
  overflow: visible

  .rhap_progress-section, .rhap_container
    width: 0
    height: 0
    overflow: hidden

  .play-button-wrapper
    .play-button-tooltip
      top: 0
      opacity: 0

    &:hover
      .play-button-tooltip
        top: -32px
        opacity: 1


  .play-button-tooltip
    text-align: center
    position: absolute
    background-color: rgb(26, 26, 26)
    color: #ffffff
    padding: 4px 6px
    font-size: 12px
    border-radius: 8px
    border: solid 2px rgb(69, 69, 69)

  .two-word
    width: 48px
    right: -4px

  .four-word
    width: 64px
    right: -12px
