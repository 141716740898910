@import "Colors"

$discount-hight: 56px

.topic-listen-page
  min-width: 300px
  color: #FFFFFF
  font-family: Roboto
  font-size: 16px
  ol
    list-style: decimal
  ul
    list-style: disc
  img
    display: initial
  .section-2
    height: calc(100vh - 84px)
    background-color: #000000
    margin: 84px 0 24px 0
  .lessonMenu
    display: none
    align-items: center
    background: #2D2D2D8F
    cursor: pointer
    padding: 15px 24px
    img
      height: 18px
      width: 18px
    span
      font-weight: bold
      font-size: 18px
      line-height: 100%
      margin-left: 8px
  .lessonMenuList
    display: none
    height: 734px
    width: 100%
    position: absolute
    top: 0
    background: #262626
    font-weight: bold
    font-size: 16px
    line-height: 19px
    text-align: center
    z-index: 2000
  .discount
    height: $discount-hight
    display: flex
    color: #000000
    justify-content: space-between
    background: linear-gradient(90deg, #FFDD15 0%, #FFA115 100%)
    padding: 8px 16px
    .discount-detail
      display: flex
      gap: 12px
      align-items: center
      font-size: 18px
      font-weight: bold
      margin: auto 0
  .discount-mobile
    display: none
    color: #000000
    flex-direction: row
    justify-content: space-between
    align-items: center
    background: linear-gradient(90deg, #FFDD15 0%, #FFA115 100%)
    border-radius: 12px
    font-size: 16px
    font-weight: bold
    padding: 16px 12px 16px 16px
  .lesson
    display: flex
    .lesson-list
      height: calc(100vh - 84px - #{$discount-hight})
      width: 30%
      overflow-y: auto
      &::-webkit-scrollbar
        width: 8px
      &::-webkit-scrollbar-track
        opacity: 0
      &::-webkit-scrollbar-thumb
        background: #4B4B4B
        border-radius: 4px
      &::-webkit-scrollbar-thumb:hover
        background: #555
      .listTitle
        display: none
      .class-title
        font-weight: bold
        font-size: 24px
        margin-bottom: 20px
        padding: 0px 4px
      .class-detail
        background: #383838
        border: 1px solid #4D4D4D
        box-sizing: border-box
        border-radius: 12px
        margin-bottom: 16px
        font-size: 14px
        padding: 16px
        .detail-item
          margin-bottom: 10px
          &:last-child
            margin-bottom: 0px
          span
            margin-left: 12px
      .class-btn
        display: grid
        grid-template-columns: 1fr 1fr
        grid-column-gap: 12px
        padding: 16px 0px
        .btn
          height: 40px
          background: #606060
          border-radius: 12px
          color: #000000
          font-size: 16px
          font-weight: bold
          line-height: 40px
          text-align: center
        .active
          background: #F6F6F6
      .class-list
        .list-item
          display: grid
          grid-template-columns: 16px auto auto
          grid-column-gap: 12px
          cursor: pointer
          padding: 12px 16px
          .item-title
            min-width: 50px
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap
        .checking
          background: #383838
          border: 1px solid #4D4D4D
          box-sizing: border-box
          border-radius: 8px
        .learning
          color: #FFDD15
        .locked
          color: #7E7E7E
    .lesson-content
      height: calc(100vh - 84px - #{$discount-hight})
      width: 70%
      position: relative
      background-color: #000000
      padding: 4%
      overflow-x: hidden
      overflow-y: auto
      .class
        display: grid
        grid-template-columns: max-content auto
        grid-column-gap: 28px
        margin-bottom: 24px
        .class-img
          img
            height: 37vw
            width: 37vw
            max-height: 210px
            max-width: 210px
            border-radius: 20px
        div
          display: table-cell
          vertical-align: middle
          margin: auto 0
        .class-title
          display: table
          font-weight: bold
          font-size: 48px
          div
            display: table-cell
            vertical-align: middle
      .avatar
        margin-bottom: 16px
        img
          height: 36px
          width: 36px
          border-radius: 12px
      .status
        display: flex
        justify-content: space-between
        margin-bottom: 56px
        .status-detail
          display: flex
          gap: 12px
          align-items: center
          margin: auto 0
          .rect
            padding: 4px 8px
            line-height: 100%
          .time
            background: #4D4D4D
            border-radius: 6px
          .free
            font-size: 14px
        .hightlight
          height: 100%
          background: #FFDD15
          border-radius: 4px
          color: #000000
          font-weight: bold
          text-align: center
        .hightlight_w
          background-color: #FFFFFF
        .play-btn
          height: 48px
          width: 30%
          max-width: 300px
          .play, .download
            border-radius: 12px
            line-height: 48px
            text-align: center
          .play
            height: 48px
            width: 100%
            color: #000000
            font-size: 16px
          .download
            display: none
            border: 1px solid #FFFFFF
            font-weight: bold
            font-size: 14px
      .class-content
        margin-bottom: 16px
        h2
          font-size: 32px
          font-weight: bold
          margin-bottom: 16px
        .detail
          color: #CCCCCC
          font-family: Roboto
          font-size: 16px
          margin: 32px 0px
          & > :first-child
            margin-top: 0px
          img
            cursor: pointer
            margin: 16px 0px
            width: 100% !important
            border-radius: 8px
          a
            color: #FFDD15
            font-size: 18px
            font-weight: bold
          strong
            color: #FFFFFF
          p
            font-size: 18px
            font-weight: normal
            padding: 10px 0px
          h1, h2, h3, h4, h5, h6
            color: #FFFFFF
            font-weight: bold
            line-height: 140%
            margin: 24px 0px 12px 0px
          h1
            font-size: 48px
          h2
            font-size: 32px
          h3
            font-size: 28px
          h4
            font-size: 24px
          h5
            font-size: 20px
          h6
            font-size: 18px
          ul, ol
            font-size: 18px
            padding: 12px 0px 12px 40px
      hr
        border-top-width: 3px
        border-radius: 3px
        border-color: #383838
  .lesson-btn, .join-btn
    display: flex
    justify-content: flex-end
    text-align: center
    a
      height: 42px
      width: 152px
      box-sizing: border-box
      border-radius: 12px
      font-weight: bold
      font-size: 16px
      text-align: center
      text-decoration: none
      line-height: 40px
      white-space: nowrap
  .lesson-btn
    gap: 24px
    margin-top: 16px
    a
      border: 1px solid #FFFFFF
  .join-btn
    gap: 10px
    a
      display: flex
      align-self: center
      align-items: center
      border: 1px solid #000000
      padding: 12px 16px
      justify-content: center
      line-height: 100%
      img
        height: 20px
        width: 20px

@media screen and ( max-width: 768px )
  .topic-listen-page
    .lessonMenu
      display: flex
    .discount
      display: none
    .discount-mobile
      display: flex
      margin-bottom: 44px
    .lesson
      .lesson-list
        height: 100vh
        width: 100%
        display: none
        position: absolute
        top: 0
        left: 0
        background: #262626
        transform: translateX(-150%)
        z-index: 2000
        .listTitle
          display: block
          background: #2D2D2D
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.75)
          font-weight: bold
          font-size: 16px
          line-height: 19px
          padding: 15px 17px
          text-align: center
          img
            position: absolute
            top: 12px
            left: 15px
            cursor: pointer
            transform: scaleX(-1)
        .list-content
          height: calc( 100vh - 48px )
          overflow-y: auto
      .lesson-content
        width: 100%
        .status
          display: block
          margin-bottom: 24px
          .play-btn
            display: grid
            grid-template-columns: 1fr 1fr
            grid-column-gap: 12px
            width: 100%
            max-width: initial
            margin-top: 24px
            .play
              width: 100%
              max-width: none
            .download
              display: block
              a
                width: 100%
        .class-content
          h2
            font-size: 28px

@media ( max-width: 375px )
  .topic-listen-page
    .discount-mobile
      font-size: 14px
    .lessonMenu
      img
        height: 16px
        width: 16px
      span
        font-size: 14px
    .lesson
      .lesson-content
        .status
          font-size: 12px
          .status-detail
            font-size: 12px
            .free
              font-size: 12px
        .class
          display: block
          .class-img
            display: none
          .class-title
            font-size: 28px
        .class-content
          h2
            font-size: 24px
          .detail
            p
              font-size: 16px
            h1, h2, h3
              margin: 28px 0px 12px 0px
            h4, h5, h6, ul
              margin: 10px 0px
            h1
              font-size: 28px
            h2
              font-size: 26px
            h3
              font-size: 24px
            h4
              font-size: 22px
            h5
              font-size: 20px
            h6
              font-size: 18px
            ul, ol
              font-size: 16px
    .lesson-btn
      justify-content: center

.openDialog
  display: block !important
  animation: fromBottom 0.8s 0s cubic-bezier(0, 0, 0, 1)
  animation-fill-mode: forwards

.dialog
  width: 100%
  height: 100vh
  display: none
  position: absolute
  top: 0
  left: 0
  background-color: black
  padding: 16px
  z-index: 1000
  .closeDialog
    margin-bottom: 12px
    img
      cursor: pointer
  .pic
    height: calc( 100vh - 24px - 12px - 32px )
    width: calc( 100vw - 32px )
    display: flex
    overflow-y: auto
    img
      margin: auto


// .close
//   animation: toLeft 0.5s 0.2s cubic-bezier(0, 0, 0, 1)
//   animation-fill-mode: forwards
//   transform: translateX(0%)

.open 
  animation: fromLeft 0.8s 0s cubic-bezier(0, 0, 0, 1)
  animation-fill-mode: forwards
  overflow: hidden !important