@import "App"

@import "topBar"
@import "Footer"
@import "Article"
@import "CommentCard"
@import "CommentInput"
@import "EpisodePlayer"
@import "SkuCard"
@import "StickyNav"
@import "SideNav"

@import "webflow"
@import "normalize"
@import "nprogress"

@import "TopicBuyPage"
@import "landing"
@import "EpisodePage"
@import "login"
@import "NotFound"
@import "PodcastPage"
@import "TopicListenPage"
@import "TopicPage"
@import "TopicBoughtList"
@import "OrderList"
@import "@components/SimpleEpisodePlayer"

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@500&display=swap')

*
  font-family: 'Red Hat Text', sans-serif !important

html, body
  padding: 0
  margin: 0
  font-size: 16px

a
  color: inherit
  text-decoration: none


*
  box-sizing: border-box

